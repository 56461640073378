<mat-card class="filters">
  <div [formGroup]="filtersForm" class="filters-wrapper">
    <ng-container *ngFor="let dateFilter of dateFilters">
      <div class="filter-field" fxLayout="row" fxLayoutAlign="start start">
        <mat-form-field class="date-filter" appearance="outline" fxFlex>
          <mat-label>{{'SHARED.FILTERS.FILTER_TYPES.' + dateFilter.toUpperCase() | translate}}</mat-label>
          <input class="date-filter" matInput [matDatepicker]="filter" [formControlName]="dateFilter">
          <mat-datepicker-toggle matSuffix [for]="filter"></mat-datepicker-toggle>
          <mat-datepicker #filter></mat-datepicker>
        </mat-form-field>
      </div>
    </ng-container>

    <ng-container *ngFor="let filterMulti of filtersMulti">
      <div class="filter-field" fxLayout="row" fxLayoutAlign="start start">
        <mat-form-field class="multiple-select" appearance="outline" fxFlex>
          <mat-label>{{'SHARED.FILTERS.FILTER_TYPES.' + filterMulti.toUpperCase() | translate}}</mat-label>
          <mat-select [formControlName]="filterMulti" multiple [attr.data-cy]="filterMulti">
            <mat-option *ngFor="let option of filtersTypes[filterMulti]" [value]="option.value" [attr.data-cy]="option.value">
              {{'SHARED.FILTERS.FILTERS_OPTIONS.' + option.name.toUpperCase() | translate}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </ng-container>
    <ng-container *ngFor="let filter of filters">
      <div class="filter-field" fxLayout="row" fxLayoutAlign="start start">
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>{{'SHARED.FILTERS.FILTER_TYPES.' + filter.toUpperCase() | translate}}</mat-label>
          <mat-select [formControlName]="filter" [attr.data-cy]="filter">
            <mat-option *ngFor="let option of filtersTypes[filter]" [value]="option.value" [attr.data-cy]="option.name"
                        [matTooltip]="'SHARED.FILTERS.FILTERS_OPTIONS.' + option.name.toUpperCase() | translate"
                        matTooltipPosition="left">
              {{'SHARED.FILTERS.FILTERS_OPTIONS.' + option.name.toUpperCase() | translate}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </ng-container>

    <ng-container *ngFor="let customFilter of customFilters">
      <div class="filter-field" fxLayout="row" fxLayoutAlign="start start">
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>{{'SHARED.FILTERS.FILTER_TYPES.' + customFilter.name.toUpperCase() | translate}}</mat-label>
          <mat-select [formControlName]="customFilter.name" [attr.data-cy]="customFilter.name">
            <mat-option value="" [attr.data-cy]="'ALL'">
              {{'SHARED.FILTERS.FILTERS_OPTIONS.ALL' | translate}}
            </mat-option>
            <mat-option *ngFor="let option of customFilter.options" [value]="option.value"
                        [matTooltip]="option.name" matTooltipPosition="left" [attr.data-cy]="option.name.toUpperCase()">
              {{option.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </ng-container>

    <ng-container *ngFor="let customMultiFilter of customMultiFilters">
      <div class="filter-field" fxLayout="row" fxLayoutAlign="start start">
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>{{'SHARED.FILTERS.FILTER_TYPES.' + customMultiFilter.name.toUpperCase() | translate}}</mat-label>
          <mat-select [formControlName]="customMultiFilter.name" multiple [attr.data-cy]="customMultiFilter.name">
            <mat-option *ngFor="let option of customMultiFilter.options" [value]="option.value"
                        [matTooltip]="option.name" matTooltipPosition="left" [attr.data-cy]="option.name.toUpperCase()">
              {{option.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </ng-container>

    <ng-container *ngFor="let taskDateFilter of taskDateFilters">
      <div class="task-date-filter-field" fxLayout="row" fxLayoutAlign="start start">
        <mat-form-field class="date-filter" appearance="outline" fxFlex>
          <mat-label>{{'SHARED.FILTERS.FILTER_TYPES.' + taskDateFilter.toUpperCase() | translate}}</mat-label>
          <input class="date-filter" matInput [matDatepicker]="filter" [formControlName]="taskDateFilter">
          <mat-datepicker-toggle matSuffix [for]="filter"></mat-datepicker-toggle>
          <mat-datepicker #filter></mat-datepicker>
        </mat-form-field>
      </div>
    </ng-container>

    <button mat-raised-button (click)="clearFilters()"
            class="clear-filters">{{'SHARED.FILTERS.CLEAR' | translate}}
    </button>
  </div>
</mat-card>
