import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tasks-container',
  templateUrl: './tasks-container.component.html',
  styleUrls: ['./tasks-container.component.scss']
})
export class TasksContainerComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
