import { EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './auth.service';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./auth.service";
var TasksService = /** @class */ (function () {
    function TasksService(http, authService) {
        this.http = http;
        this.authService = authService;
        this.onEditEvent = new EventEmitter();
    }
    TasksService.prototype.getTasksList = function (params) {
        return this.http.get(environment.apiUrl + "/" + this.authService.getUserCompanyId() + "/tasks", { params: params })
            .pipe(map(function (res) { return res; }));
    };
    TasksService.prototype.getTasksListByDay = function (date, companyBranchId) {
        var params = {
            dateFrom: date,
            dateTo: date,
            status: 'new',
            companyBranchId: companyBranchId
        };
        return this.http.get(environment.apiUrl + "/" + this.authService.getUserCompanyId() + "/tasks", { params: params })
            .pipe(map(function (res) { return res; }));
    };
    TasksService.prototype.getTask = function (taskId) {
        return this.http.get(environment.apiUrl + "/" + this.authService.getUserCompanyId() + "/tasks/" + taskId).pipe(map(function (res) { return res.task; }));
    };
    TasksService.prototype.updateTask = function (taskId, taskForwarder) {
        return this.http.put(environment.apiUrl + "/" + this.authService.getUserCompanyId() + "/tasks/" + taskId, taskForwarder).pipe(map(function (res) { return res.task; }));
    };
    TasksService.ngInjectableDef = i0.defineInjectable({ factory: function TasksService_Factory() { return new TasksService(i0.inject(i1.HttpClient), i0.inject(i2.AuthService)); }, token: TasksService, providedIn: "root" });
    return TasksService;
}());
export { TasksService };
