import { EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { removeAccents } from '../shared/utils/remove-accents';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./auth.service";
var UsersService = /** @class */ (function () {
    function UsersService(http, authService) {
        this.http = http;
        this.authService = authService;
        this.onEditEvent = new EventEmitter();
    }
    UsersService.prototype.getUsersList = function (params) {
        return this.http.get(environment.apiUrl + "/" + this.authService.getUserCompanyId() + "/users", { params: params })
            .pipe(map(function (res) {
            res.users.forEach(function (user) {
                return user.avatarName = removeAccents(user.name + " " + user.surname);
            });
            return res;
        }));
    };
    UsersService.prototype.getUser = function (userUuid) {
        return this.http.get(environment.apiUrl + "/" + this.authService.getUserCompanyId() + "/users/" + userUuid).pipe(map(function (res) {
            res.user.avatarName = removeAccents(res.user.name + " " + res.user.surname);
            return res.user;
        }));
    };
    UsersService.prototype.createUser = function (user) {
        return this.http.post(environment.apiUrl + "/" + this.authService.getUserCompanyId() + "/users", user).pipe(map(function (res) { return res.user; }));
    };
    UsersService.prototype.updateUser = function (userUuid, user) {
        return this.http.put(environment.apiUrl + "/" + this.authService.getUserCompanyId() + "/users/" + userUuid, user);
    };
    UsersService.prototype.deleteUser = function (userUuid) {
        return this.http.delete(environment.apiUrl + "/" + this.authService.getUserCompanyId() + "/users/" + userUuid);
    };
    UsersService.prototype.getCompanyBranchList = function () {
        return this.http.get(environment.apiUrl + "/" + this.authService.getUserCompanyId() + "/companyBranches");
    };
    UsersService.prototype.getAclGroupsList = function () {
        return this.http.get(environment.apiUrl + "/" + this.authService.getUserCompanyId() + "/users/groups");
    };
    UsersService.ngInjectableDef = i0.defineInjectable({ factory: function UsersService_Factory() { return new UsersService(i0.inject(i1.HttpClient), i0.inject(i2.AuthService)); }, token: UsersService, providedIn: "root" });
    return UsersService;
}());
export { UsersService };
