import { Component, OnInit } from '@angular/core';
import { FuseConfigService } from '../../../../../@fuse/services/config.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { FuseTranslationLoaderService } from '../../../../../@fuse/services/translation-loader.service';
import { locale as polish } from '../../../../translations/pl';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material';
import { AuthService } from '../../../../services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public loginForm: FormGroup;
  public isLoading = false;

  constructor(private router: Router,
              private translateService: TranslateService,
              private authService: AuthService,
              private snackBar: MatSnackBar,
              private formBuilder: FormBuilder,
              private _fuseConfigService: FuseConfigService,
              private _fuseTranslationLoaderService: FuseTranslationLoaderService) {
    // Configure the layout
    this._fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },
        toolbar: {
          hidden: true
        },
        footer: {
          hidden: true
        },
        sidepanel: {
          hidden: true
        }
      }
    };

    this._fuseTranslationLoaderService.loadTranslations(polish);
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required]
    });
  }

  public login() {
    this.isLoading = true;
    this.authService.login(this.loginForm.value).subscribe(
      () => {
        this.isLoading = false;
      }, () => {
        this.isLoading = false;
        this.snackBar.open(this.translateService.instant('SHARED.MESSAGES.ERROR'), '', {duration: 5000});
      }
    );
  }
}
