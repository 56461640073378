<div class="dialog-content-wrapper">
    <mat-toolbar matDialogTitle class="mat-accent m-0">
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">{{'TASKS.TITLE_VIEW' | translate}}
                - {{'TASKS.TYPES.RECEIVING' | translate}}</span>
            <button mat-icon-button (click)="taskEditorDialogRef.close()" aria-label="Close dialog">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>
    <div class="task-status" *ngIf="mode === 'view' && task">
        Status: {{'TASKS.STATUSES.' + task?.status.toUpperCase() | translate}}
    </div>
    <mat-horizontal-stepper class="task-content-wrapper" mat-dialog-content #stepper="matHorizontalStepper"
                            (selectionChange)="changeStep($event)" [selectedIndex]="indexStep">
        <mat-step [completed]="false" [stepControl]="taskReceivingForm">
            <ng-template matStepLabel><h3><b>{{'TASKS.GENERAL_INFO' | translate}}</b></h3></ng-template>
            <div class="general-info-step">
                <form class="form" [formGroup]="taskReceivingForm">
                    <div class="full-width-wrapper-vertical-row">
                        <mat-form-field appearance="outline" class="no-errors-spacer" fxFlex>
                            <mat-label>{{'TASKS.NAME' | translate}}</mat-label>
                            <input matInput formControlName="name" required [attr.data-cy]="'taskName'">
                        </mat-form-field>
                    </div>

                    <div class="full-width-wrapper-horizontal">
                        <div class="input-wrapper-task-receiving">
                            <mat-form-field appearance="outline" fxFlex>
                                <mat-label>{{'TASKS.COMPANY_BRANCH' | translate}}</mat-label>
                                <mat-select formControlName="companyBranchId" required>
                                    <mat-option *ngFor="let branch of companyBranches" [value]="branch.uuid">
                                        {{ branch.name }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <div class="input-wrapper-task-receiving">
                            <mat-form-field appearance="outline" fxFlex>
                                <mat-label>{{'TASKS.TRANSPORT' | translate}}</mat-label>
                                <mat-select formControlName="transport"
                                            [compareWith]="compareObjectsByUuid"
                                            required
                                            [attr.data-cy]="'setTransport'">
                                    <mat-option *ngFor="let transport of getTransportOptions()" [value]="transport"
                                                [attr.data-cy]="transport.name">
                                        {{ transport.name }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="full-width-wrapper-vertical-row">
                        <mat-form-field appearance="outline" fxFlex>
                            <mat-label>{{'TASKS.DATE' | translate}}</mat-label>
                            <input matInput [matDatepicker]="startDate" formControlName="startDate" [min]="todayDate"
                                   required
                                   (dateChange)="setStartDate($event.value)">
                            <mat-datepicker-toggle matSuffix [for]="startDate"
                                                   [attr.data-cy]="'startDate'"></mat-datepicker-toggle>
                            <mat-datepicker #startDate></mat-datepicker>
                        </mat-form-field>
                    </div>

                    <div class="full-width-wrapper-horizontal">
                        <div class="input-wrapper-task-receiving">
                            <mat-form-field appearance="outline" fxFlex>
                                <mat-label>{{'TASKS.START_TIME' | translate}}</mat-label>
                                <mat-select formControlName="startTime" required [attr.data-cy]="'startTime'">
                                    <mat-option *ngFor="let time of startTimeOptions; let i=index" [value]="time"
                                                [disabled]="taskReceivingForm.get('endTime').value &&
                           startTimeOptions.includes(taskReceivingForm.get('endTime').value) &&
                           i+1 > startTimeOptions.indexOf(taskReceivingForm.get('endTime').value)"
                                                [attr.data-cy]="time">
                                        {{ time }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <div class="input-wrapper-task-receiving">
                            <mat-form-field appearance="outline" fxFlex>
                                <mat-label>{{'TASKS.END_TIME' | translate}}</mat-label>
                                <mat-select formControlName="endTime" required [attr.data-cy]="'endTime'">
                                    <mat-option *ngFor="let time of endTimeOptions; let i=index" [value]="time"
                                                [disabled]="taskReceivingForm.get('startTime').value &&
                          i < startTimeOptions.indexOf(taskReceivingForm.get('startTime').value)" [attr.data-cy]="time">
                                        {{ time }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="input-wrapper-task-receiving">
                            <mat-form-field appearance="outline" fxFlex>
                                <mat-label>{{'TASKS.REG_NUMBER_FRONT' | translate}}</mat-label>
                                <input formControlName="registrationNumFront" matInput [attr.data-cy]="'numberFront'">
                            </mat-form-field>
                        </div>
                        <div class="input-wrapper-task-receiving">
                            <mat-form-field appearance="outline" fxFlex>
                                <mat-label>{{'TASKS.REG_NUMBER_BACK' | translate}}</mat-label>
                                <input formControlName="registrationNumBack" matInput [attr.data-cy]="'numberBack'">
                            </mat-form-field>
                        </div>
                        <div class="input-wrapper-task-receiving">
                            <mat-form-field appearance="outline" fxFlex>
                                <mat-label>{{'TASKS.REFERENCE_NUMBER' | translate}}</mat-label>
                                <input formControlName="referenceNumber" matInput [attr.data-cy]="'referenceNumber'">
                            </mat-form-field>
                        </div>

                    </div>
                    <div class="full-width-wrapper-vertical"
                         [ngClass]="mode==='add' ? 'comment-col' : 'comment-col-view'">
                        <h3 class="m-0"> {{'TASKS.COMMENT' | translate}} </h3>
                        <mat-form-field appearance="outline" fxFlex="auto" [formGroup]="taskReceivingForm">
                            <textarea formControlName="comment" matInput [attr.data-cy]="'comment'"></textarea>
                        </mat-form-field>
                    </div>
                    <ng-container *ngIf="mode==='add' || mode ==='clone'">
                        <div class="full-width-wrapper-vertical">
                            <div class="input-wrapper-task-receiving recurring-checkbox">
                                <mat-checkbox formControlName="recurring" [attr.data-cy]="'recurring'">
                                    {{'TASKS.RECURRING.CHECKBOX' | translate}}
                                </mat-checkbox>
                            </div>
                        </div>
                        <div *ngIf="taskReceivingForm.get('recurring').value" formGroupName="recurringInfo"
                             class="full-width-wrapper-horizontal">
                            <div class="input-wrapper-task-receiving">
                                <mat-form-field appearance="outline" fxFlex>
                                    <mat-label>{{'TASKS.RECURRING.INTERVAL' | translate}}</mat-label>
                                    <input formControlName="intervalValue" matInput required
                                           [attr.data-cy]="'intervalValue'">
                                </mat-form-field>
                            </div>
                            <div class="input-wrapper-task-receiving">
                                <mat-form-field appearance="outline" fxFlex>
                                    <mat-label>{{'TASKS.RECURRING.RANGE_DATE_END' | translate}}</mat-label>
                                    <input matInput [matDatepicker]="rangeDateEndPicker" formControlName="rangeDateEnd"
                                           [min]="rangeDateEndMin"
                                           [max]="rangeDateEndMax" required readonly>
                                    <mat-datepicker-toggle matSuffix [for]="rangeDateEndPicker"
                                                           [attr.data-cy]="'rangeDateEnd'"></mat-datepicker-toggle>
                                    <mat-datepicker #rangeDateEndPicker></mat-datepicker>
                                </mat-form-field>
                            </div>
                            <div class="full-width-wrapper-horizontal weekdays-checkbox-wrapper"
                                 formGroupName="weekdays"
                                 fxLayoutAlign="start start">
                                <mat-checkbox labelPosition="before" formControlName="Monday" class="weekday-checkbox"
                                              [attr.data-cy]="'monday'">
                                    {{'TASKS.RECURRING.MONDAY' | translate}}
                                </mat-checkbox>
                                <mat-checkbox labelPosition="before" formControlName="Tuesday" class="weekday-checkbox"
                                              [attr.data-cy]="'tuesday'">
                                    {{'TASKS.RECURRING.TUESDAY' | translate}}
                                </mat-checkbox>
                                <mat-checkbox labelPosition="before" formControlName="Wednesday"
                                              class="weekday-checkbox" [attr.data-cy]="'wednesday'">
                                    {{'TASKS.RECURRING.WEDNESDAY' | translate}}
                                </mat-checkbox>
                                <mat-checkbox labelPosition="before" formControlName="Thursday" class="weekday-checkbox"
                                              [attr.data-cy]="'thursday'">
                                    {{'TASKS.RECURRING.THURSDAY' | translate}}
                                </mat-checkbox>
                                <mat-checkbox labelPosition="before" formControlName="Friday" class="weekday-checkbox"
                                              [attr.data-cy]="'friday'">
                                    {{'TASKS.RECURRING.FRIDAY' | translate}}
                                </mat-checkbox>
                                <mat-checkbox labelPosition="before" formControlName="Saturday" class="weekday-checkbox"
                                              [attr.data-cy]="'saturday'">
                                    {{'TASKS.RECURRING.SATURDAY' | translate}}
                                </mat-checkbox>
                                <mat-checkbox labelPosition="before" formControlName="Sunday" class="weekday-checkbox"
                                              [attr.data-cy]="'sunday'">
                                    {{'TASKS.RECURRING.SUNDAY' | translate}}
                                </mat-checkbox>
                            </div>
                        </div>
                    </ng-container>
                </form>
            </div>
            <div *ngIf="mode === 'add' || mode === 'clone'" class="btn-next">
                <button mat-button matStepperNext
                        [attr.data-cy]="'nextStep'">{{'TASKS.STEPPER.NEXT' | translate}}</button>
            </div>
        </mat-step>
        <mat-step [completed]="false" [stepControl]="taskReceivingForm">
            <ng-template matStepLabel><h3><b> {{'TASKS.ORDERS' | translate}} <sup>*</sup></b></h3></ng-template>
            <mat-accordion>
                <mat-expansion-panel [expanded]="isExpanded[i]"
                                     *ngFor="let order of taskReceivingForm.get('taskData')['controls']; let i=index">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            {{'TASKS.ORDER'| translate}}
                        </mat-panel-title>
                        <mat-panel-description *ngIf="order.value.customerCompany && !order.value.order">
                            {{order.controls.order.value.customerCompany?.name || ""}}
                            {{order.controls.order.value.orderNumber ? "[" + order.controls.order.value.orderNumber + "]" : ""}}
                        </mat-panel-description>
                        <mat-panel-description *ngIf="order.value.customerCompany && order.value.order">
                            {{order.value.customerCompany?.name || ""}}
                            {{order.value.order.orderNumber && "[" + order.value.order.orderNumber + "]" || ""}}
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <form [formGroup]="taskReceivingForm">
                        <div class="full-width-wrapper-vertical" formArrayName="taskData">
                            <div [formGroupName]="i" class="full-width-wrapper-vertical-order">
                                <mat-form-field class="company-select" appearance="outline" fxFlex>
                                    <mat-label>{{'TASKS.ORDER' | translate}}</mat-label>
                                    <mat-select formControlName="order"
                                                [compareWith]="compareObjectsByUuid"
                                                (valueChange)="pickOrder($event, i)"
                                                required
                                                [attr.data-cy]="'setOrder'">
                                        <mat-option>
                                        </mat-option>
                                        <mat-option [value]="{uuid: 'other'}" [attr.data-cy]="'other'">
                                            -- {{'TASKS.OTHER' | translate}} --
                                        </mat-option>
                                        <mat-option *ngFor="let order of getOrdersOptions(i)" [value]="order"
                                                    [attr.data-cy]="order.customerCompany.name.split(' ').join('-')">
                                            {{ order?.customerCompany?.name }}
                                            <small *ngIf="order && order.orderNumber"> [{{ order?.orderNumber }}
                                                ]</small>
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>

                                <div class="full-width-wrapper-vertical full-name-select">
                                    <mat-form-field appearance="outline" fxFlex="auto">
                                        <mat-label>{{'TASKS.CUSTOMER_COMPANY' | translate}}</mat-label>
                                        <mat-select formControlName="customerCompany"
                                                    [compareWith]="compareObjectsByUuid"
                                                    (valueChange)="onChangeCustomerCompany($event, i)" #companySelect
                                                    required [attr.data-cy]="'customerCompany'">
                                            <mat-option (click)="addCustomerCompany(i)" [attr.data-cy]="'companyAdd'">
                        <span class="add-item">
                          <mat-icon class="add-item-icon" matSuffix (click)="addCustomerCompany(i)"
                                    [matTooltip]="'CUSTOMER_COMPANIES.TITLE_ADD' | translate"
                                    matTooltipPosition="above">
                            add
                          </mat-icon>
                            {{ 'CUSTOMER_COMPANIES.TITLE_ADD' | translate }}
                        </span>
                                            </mat-option>
                                            <mat-option class="full-name-option"
                                                        *ngFor="let customerCompany of getCustomerCompaniesOptions(i)"
                                                        [value]="customerCompany"
                                                        [attr.data-cy]="customerCompany.name.split(' ').join('-')">
                       <span matTooltipPosition="above" matTooltipClass="multiline-tooltip"
                             [matTooltip]="getTooltipCompany(customerCompany)">
                      {{ customerCompany.name }}
                       </span>
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>

                                    <mat-form-field appearance="outline" fxFlex="auto">
                                        <mat-label>{{'ORDERS.RECEIVING_PLACE' | translate}}</mat-label>
                                        <mat-select formControlName="customerCompanyBranch" required
                                                    (ngModelChange)="handleCCBranchChange($event,i)"
                                                    [attr.data-cy]="'customerBranch'">
                                            <mat-option *ngFor="let branch of
                        customerCompanyBranches[getFormCompanyUuid(i)];
                        let i=index" [value]="branch.uuid">
                            <span matTooltipPosition="above" matTooltipClass="multiline-tooltip"
                                  [matTooltip]="getTooltip(branch)" [attr.data-cy]="branch.name">
                          {{ branch.name }}
                            </span>
                                            </mat-option>
                                            <mat-option value="other" [attr.data-cy]="'otherBranch'">
                                                {{ "ORDERS.CUSTOMER_COMPANY_BRANCH.OTHER" | translate}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>

                                <div class="full-width-wrapper-horizontal">

                                    <div class="input-wrapper-task-receiving">
                                        <mat-form-field appearance="outline" fxFlex>
                                            <mat-label>{{'ORDERS.ACCEPTED_BY_SALES' | translate}}</mat-label>
                                            <mat-select formControlName="salesAccepted" required
                                                        [attr.data-cy]="'acceptedBySales'">
                                                <mat-option [value]="true"
                                                            [attr.data-cy]="'YES'">{{'SHARED.YES' | translate}}</mat-option>
                                                <mat-option [value]="false"
                                                            [attr.data-cy]="'NO'">{{'SHARED.NO' | translate}}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>

                                    <div class="input-wrapper-task-receiving">
                                        <mat-form-field appearance="outline" fxFlex>
                                            <mat-label>{{'ORDERS.COMPANY_BRANCH' | translate}}</mat-label>
                                            <mat-select formControlName="companyBranchId" required
                                                        [attr.data-cy]="'companyBranch'">
                                                <mat-option *ngFor="let branch of companyBranches" [value]="branch.uuid"
                                                            [attr.data-cy]="branch.name.toUpperCase()">
                                                    {{ branch.name }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>

                                    <div class="input-wrapper-task-receiving">
                                        <mat-form-field appearance="outline" fxFlex>
                                            <mat-label>{{'ORDERS.RECEIVING_TIME_FROM' | translate}}</mat-label>
                                            <mat-select formControlName="availableHoursFrom" required
                                                        [attr.data-cy]="'availableHoursFrom'">
                                                <mat-option
                                                        *ngFor="let time of startTimeOptionsReceiving; let timeFromIndex=index"
                                                        [value]="time"
                                                        [disabled]="availableHoursFromDisabled(i, timeFromIndex)"
                                                        [attr.data-cy]="time">
                                                    {{ time }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>

                                    <div class="input-wrapper-task-receiving">
                                        <mat-form-field appearance="outline" fxFlex>
                                            <mat-label>{{'ORDERS.RECEIVING_TIME_TO' | translate}}</mat-label>
                                            <mat-select formControlName="availableHoursTo" required
                                                        [attr.data-cy]="'availableHoursTo'">
                                                <mat-option
                                                        *ngFor="let time of endTimeOptionsReceiving; let timeToIndex=index"
                                                        [value]="time"
                                                        [disabled]="availableHoursToDisabled(i, timeToIndex)"
                                                        [attr.data-cy]="time">
                                                    {{ time }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>

                                    <div class="input-wrapper-task-receiving">
                                        <mat-form-field appearance="outline" fxFlex>
                                            <mat-label>{{'ORDERS.SUGGESTED_DATE_RECEIVING' | translate}}</mat-label>
                                            <input matInput [matDatepicker]="suggestedDate"
                                                   formControlName="suggestedDate" [min]="todayDate" readonly required>
                                            <mat-datepicker-toggle matSuffix [for]="suggestedDate"
                                                                   [attr.data-cy]="'suggestedDate'"></mat-datepicker-toggle>
                                            <mat-datepicker #suggestedDate></mat-datepicker>
                                        </mat-form-field>
                                    </div>

                                    <div class="input-wrapper-task-receiving">
                                        <mat-form-field appearance="outline" fxFlex>
                                            <mat-label>{{'ORDERS.REFERENCE_NUMBER' | translate}}</mat-label>
                                            <input formControlName="referenceNumber" matInput
                                                   [attr.data-cy]="'referenceNumber'">
                                        </mat-form-field>
                                    </div>

                                    <div *ngIf="taskReceivingForm.get('taskData').controls[i].get('customerCompanyBranch')"
                                         formGroupName="customerCompanyBranchOther"
                                         class="full-width-wrapper-horizontal">
                                        <div class="full-width-wrapper-horizontal">
                                            <h3> {{'ORDERS.RECEIVING_PLACE' | translate}}</h3>
                                        </div>
                                        <div></div>
                                        <div class="input-wrapper-task-receiving"
                                             *ngIf="taskReceivingForm.get('taskData').controls[i].get('customerCompanyBranch').value==='other'">
                                            <mat-form-field appearance="outline" fxFlex>
                                                <mat-label>{{'ORDERS.CUSTOMER_COMPANY_BRANCH.NAME' | translate}}</mat-label>
                                                <input formControlName="name" matInput required
                                                       [attr.data-cy]="'otherBranchName'">
                                            </mat-form-field>
                                        </div>
                                        <div class="input-wrapper-task-receiving">
                                            <mat-form-field appearance="outline" fxFlex>
                                                <mat-label>{{'ORDERS.CUSTOMER_COMPANY_BRANCH.STREET' | translate}}</mat-label>
                                                <input formControlName="street" matInput required
                                                       [attr.data-cy]="'otherBranchStreet'">
                                            </mat-form-field>
                                        </div>

                                        <div class="input-wrapper-task-receiving">
                                            <mat-form-field appearance="outline" fxFlex>
                                                <mat-label>{{'ORDERS.CUSTOMER_COMPANY_BRANCH.POSTAL_CODE' | translate}}</mat-label>
                                                <input formControlName="postalCode" matInput required
                                                       [attr.data-cy]="'otherBranchPostalCode'">
                                            </mat-form-field>
                                        </div>

                                        <div class="input-wrapper-task-receiving">
                                            <mat-form-field appearance="outline" fxFlex>
                                                <mat-label>{{'ORDERS.CUSTOMER_COMPANY_BRANCH.CITY' | translate}}</mat-label>
                                                <input formControlName="city" matInput required
                                                       [attr.data-cy]="'otherBranchCity'">
                                            </mat-form-field>
                                        </div>

                                        <div class="input-wrapper-task-receiving">
                                            <mat-form-field appearance="outline" fxFlex>
                                                <mat-label>{{'ORDERS.CUSTOMER_COMPANY_BRANCH.COUNTRY' | translate}}</mat-label>
                                                <input formControlName="country" matInput required
                                                       [attr.data-cy]="'otherBranchCountry'">
                                            </mat-form-field>
                                        </div>

                                        <div class="input-wrapper-task-receiving">
                                            <mat-form-field appearance="outline" fxFlex>
                                                <mat-label>{{'ORDERS.CUSTOMER_COMPANY_BRANCH.CONTACT' | translate}}</mat-label>
                                                <input formControlName="contact" matInput
                                                       [attr.data-cy]="'otherBranchContact'">
                                            </mat-form-field>
                                        </div>

                                        <div class="input-wrapper-task-receiving">
                                            <mat-form-field appearance="outline" fxFlex>
                                                <mat-label>{{'ORDERS.CUSTOMER_COMPANY_BRANCH.PHONE' | translate}}</mat-label>
                                                <input formControlName="phone" matInput required
                                                       [attr.data-cy]="'otherBranchPhone'">
                                            </mat-form-field>
                                        </div>

                                        <div class="input-wrapper-task-receiving">
                                            <mat-form-field appearance="outline" fxFlex>
                                                <mat-label>{{'ORDERS.CUSTOMER_COMPANY_BRANCH.CLIENT_COMMENT' | translate}}</mat-label>
                                                <input formControlName="note" matInput
                                                       [attr.data-cy]="'otherBranchNote'">
                                            </mat-form-field>
                                        </div>

                                        <div class="full-width-wrapper-vertical">
                                            <h3 class="m-0"> {{'ORDERS.INTERNAL_COMMENT' | translate}}</h3>
                                            <mat-form-field appearance="outline" fxFlex="auto">
                                                <textarea formControlName="order_comment" matInput
                                                          [attr.data-cy]="'order_comment'" readonly></textarea>
                                            </mat-form-field>
                                        </div>

                                    </div>

                                    <div class="full-width-wrapper-vertical">
                                        <h3> {{'ORDERS.BDO' | translate}}</h3>

                                        <div class="full-width-wrapper-horizontal">
                                            <div class="input-wrapper-task-receiving bdo-wrapper-input">
                                                <mat-form-field appearance="outline" fxFlex>
                                                    <mat-label>{{'ORDERS.BDO_NUMBER' | translate}}</mat-label>
                                                    <input formControlName="bdo"
                                                           type="number"
                                                           matInput
                                                           [attr.data-cy]="'bdoNumber'"
                                                           [required]="!taskReceivingForm.get('taskData').controls[i].get('bdoFree').value">
                                                </mat-form-field>
                                            </div>
                                            <div class="input-wrapper-task-receiving bdo-checkbox-wrapper bdo-wrapper">
                                                <div class="or-text">{{'SHARED.OR' | translate}}</div>
                                                <mat-checkbox class="bdo-required" formControlName="bdoFree"
                                                              [attr.data-cy]="'bdoCheckbox'">
                                                    {{'ORDERS.NO_BDO_CHECKBOX' | translate}}
                                                </mat-checkbox>
                                            </div>
                                            <div class="input-wrapper-task-receiving bdo-checkbox-wrapper bdo-wrapper">
                                                <div class="or-text">{{'SHARED.OR' | translate}}</div>
                                                <mat-checkbox class="bdo-required" formControlName="bdoPeopleCollection"
                                                              [attr.data-cy]="'bdoPeopleCollection'">
                                                    {{'ORDERS.BDO_PEOPLE_COLLECTION' | translate}}
                                                </mat-checkbox>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="full-width-wrapper-vertical last-margin">
                                        <app-items-list-picker-form
                                                [mode]="mode"
                                                [required]="true"
                                                [formControl]="taskReceivingForm.get('taskData').controls[i].get('orderData')"
                                        >
                                        </app-items-list-picker-form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </mat-expansion-panel>
            </mat-accordion>
            <div *ngIf="mode === 'add' ||  (mode === 'edit' && task && task.status !== taskStatuses.Cancelled)"
                 class="mb-24 btn-add" fxLayout="row" fxLayoutAlign="center start">
                <button mat-button color="primary"
                        (click)="addOrderSubform(taskReceivingForm.get('taskData').value.length)"
                        [attr.data-cy]="'addOrderSubform'">
                    {{'TASKS.ADD_ORDER' | translate}}
                </button>
            </div>
        </mat-step>

        <!--STEP 3-->
        <mat-step [completed]="false" [stepControl]="taskReceivingForm">
            <ng-template matStepLabel><h3><b>{{'TASKS.FORWARDER.NAME' | translate}}</b></h3></ng-template>
            <div class="general-info-step">
                <form class="form" [formGroup]="forwarderForm">
                    <div class="full-width-wrapper-vertical-row">
                        <mat-form-field appearance="outline" class="no-errors-spacer" fxFlex>
                            <mat-label>{{'TASKS.FORWARDER.COMPANY_NAME' | translate}}</mat-label>
                            <input [disabled]="task?.status.toUpperCase() !== 'NOTIFIED' && task?.status.toUpperCase() !== 'SEND_TO_FORWARDER'"
                                   matInput formControlName="companyName" [attr.data-cy]="'forwarderCompanyName'">
                        </mat-form-field>
                    </div>
                    <div class="full-width-wrapper-vertical-row">
                        <mat-form-field appearance="outline" fxFlex>
                            <mat-label>{{'TASKS.FORWARDER.CAR_SELECT' | translate}}</mat-label>
                            <mat-select [formControl]="companyCarSelect">
                                <mat-option *ngFor="let companyCar of companyCars" [value]="companyCar">
                                    {{ companyCar.name }} - {{companyCar.driverName}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="full-width-wrapper-horizontal">
                        <div class="input-wrapper-task-receiving">
                            <mat-form-field appearance="outline" class="no-errors-spacer" fxFlex>
                                <mat-label>{{'TASKS.FORWARDER.DRIVER_NAME' | translate}}</mat-label>
                                <input [disabled]="task?.status.toUpperCase() !== 'NOTIFIED' && task?.status.toUpperCase() !== 'SEND_TO_FORWARDER'"
                                       matInput formControlName="driverName" [attr.data-cy]="'forwarderDriverName'">
                            </mat-form-field>
                        </div>
                        <div class="input-wrapper-task-receiving">
                            <mat-form-field appearance="outline" fxFlex>
                                <mat-label>{{'TASKS.FORWARDER.DRIVER_PHONE' | translate}}</mat-label>
                                <input [disabled]="task?.status.toUpperCase() !== 'NOTIFIED' && task?.status.toUpperCase() !== 'SEND_TO_FORWARDER'"
                                       formControlName="driverPhone" matInput [attr.data-cy]="'forwarderDriverPhone'">
                            </mat-form-field>
                        </div>
                        <div class="input-wrapper-task-receiving">
                            <mat-form-field appearance="outline" fxFlex>
                                <mat-label>{{'TASKS.FORWARDER.REGISTRATION_FRONT' | translate}}</mat-label>
                                <input [disabled]="task?.status.toUpperCase() !== 'NOTIFIED' && task?.status.toUpperCase() !== 'SEND_TO_FORWARDER'"
                                       formControlName="registrationNumberFront" matInput
                                       [attr.data-cy]="'forwarderRegistrationNumberFront'">
                            </mat-form-field>
                        </div>
                        <div class="input-wrapper-task-receiving">
                            <mat-form-field appearance="outline" fxFlex>
                                <mat-label>{{'TASKS.FORWARDER.REGISTRATION_BACK' | translate}}</mat-label>
                                <input [disabled]="task?.status.toUpperCase() !== 'NOTIFIED' && task?.status.toUpperCase() !== 'SEND_TO_FORWARDER'"
                                       formControlName="registrationNumberBack" matInput
                                       [attr.data-cy]="'forwarderRegistrationNumberBack'">
                            </mat-form-field>
                        </div>
                        <div class="input-wrapper-task-receiving">
                            <mat-form-field appearance="outline" fxFlex>
                                <mat-label>{{'TASKS.FORWARDER.BDO' | translate}}</mat-label>
                                <input [disabled]="task?.status.toUpperCase() !== 'NOTIFIED' && task?.status.toUpperCase() !== 'SEND_TO_FORWARDER'"
                                       formControlName="bdo" matInput [attr.data-cy]="'forwarderBdo'">
                            </mat-form-field>
                        </div>
                        <div class="input-wrapper-task-receiving">
                            <mat-form-field appearance="outline" fxFlex>
                                <mat-label>{{'TASKS.FORWARDER.CAR_NAME' | translate}}</mat-label>
                                <input [disabled]="task?.status.toUpperCase() !== 'NOTIFIED' && task?.status.toUpperCase() !== 'SEND_TO_FORWARDER'"
                                       formControlName="carName" matInput [attr.data-cy]="'forwarderCarName'">
                            </mat-form-field>
                        </div>
                        <div class="input-wrapper-task-receiving">
                            <mat-form-field appearance="outline" fxFlex>
                                <mat-label>{{'TASKS.FORWARDER.CAR_TYPE' | translate}}</mat-label>
                                <input [disabled]="task?.status.toUpperCase() !== 'NOTIFIED' && task?.status.toUpperCase() !== 'SEND_TO_FORWARDER'"
                                       formControlName="carType" matInput [attr.data-cy]="'forwarderCarType'">
                            </mat-form-field>
                        </div>
                        <!--            <div class="input-wrapper-task-receiving">-->
                        <!--              <mat-form-field appearance="outline" fxFlex>-->
                        <!--                <mat-label>{{'TASKS.FORWARDER.EXPENSE' | translate}}</mat-label>-->
                        <!--                <input formControlName="expense" matInput [attr.data-cy]="'forwarderExpense'">-->
                        <!--              </mat-form-field>-->
                        <!--            </div>-->
                        <div *ngIf="task?.status.toUpperCase() === 'INVOICED'" class="input-wrapper-task-receiving">
                            <mat-form-field appearance="outline" fxFlex>
                                <mat-label>{{'TASKS.FORWARDER.INVOICE_NUMBER' | translate}}</mat-label>
                                <input [disabled]="task?.status.toUpperCase() !== 'REALIZED'"
                                       formControlName="invoice" matInput [attr.data-cy]="'forwarderInvoiceNumber'">
                            </mat-form-field>
                        </div>
                        <div class="input-wrapper-task-receiving">
                            <mat-form-field appearance="outline" fxFlex>
                                <mat-label>{{'TASKS.FORWARDER.KILOMETERS' | translate}}</mat-label>
                                <input formControlName="forwarderKilometers" matInput
                                       [attr.data-cy]="'forwarderKilometers'">
                            </mat-form-field>
                        </div>
                        <div class="full-width-wrapper-horizontal">
                            <div class="full-width-wrapper-vertical-row">
                                <mat-form-field appearance="outline" class="no-errors-spacer" fxFlex>
                                    <mat-label>{{'TASKS.FORWARDER.ROUTE_DESCRIPTION' | translate}}</mat-label>
                                    <textarea matInput formControlName="forwarderRouteDescription"
                                              [attr.data-cy]="'forwarderRouteDescription'"></textarea>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </mat-step>
    </mat-horizontal-stepper>

    <div class="pb-0 task-number-position" fusePerfectScrollbar>
        <div *ngIf="mode !== 'add' && mode !== 'clone' && task && indexStep===0" class="full-width-wrapper-vertical">
            <hr>
            <h3 class="pb-task"> {{'TASKS.NUMBER' | translate}} </h3>
            <div class="pb-task">{{task.taskNumber || ('TASKS.NO_NUMBER' | translate)}}</div>
        </div>
    </div>

    <div class="action-btn" fxLayout="row" fxLayoutAlign="end center">
        <app-task-pdf-downloader class="pdf-downloader"
                                 [task]="task"
                                 [branch]="pdfBranch"
                                 [forwarder]="pdfForwarder"
        >
        </app-task-pdf-downloader>
        <button
                mat-button
                class="save-button"
                [attr.data-cy]="'saveTask'"
                (click)="submitForm()"
                [disabled]="taskReceivingForm.invalid || isLoading">
            <span *ngIf="!isLoading"> {{'TASKS.SAVE' | translate}}</span>
            <span *ngIf="isLoading" class="loading-spinner-wrapper">
        <mat-progress-spinner mode="indeterminate"
                              [strokeWidth]="2"
                              [diameter]="20">
        </mat-progress-spinner>
      </span>
        </button>
    </div>
</div>