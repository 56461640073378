import {OrderExport, OrderImport, OrderReceiving} from './orders.model';
import {FilteredCompany, FilteredFraction, FilteredOrderData} from './filtered-items.model';
import {Transport} from './transport.model';
import {User} from './users.model';
import {CompanyBranch} from './company.model';
import {Company} from './companies.model';
import {Fraction} from './fractions.model';
import {PackageType} from './package.model';
import {RecyclingItem} from './recycling.model';
import { CustomerCompanyBranch } from './company-branches.model';
import {FormControl} from "@angular/forms";

export enum TaskTypes {
  Import = 'import',
  Export = 'export',
  Receiving = 'receiving'
}

export enum TaskStatuses {
  New = 'new',
  WaitingForAccept = 'waiting_for_accept',
  Cancelled = 'cancelled',
  Done = 'done'
}

export interface TasksListResponse {
  totalCount: number;
  tasks: CalendarTask[];
}

export class CalendarTask {
  uuid: string;
  taskType: string;
  startDate: string;
  endDate: string;
  name: string;
  registrationNumFront?: string;
  registrationNumBack?: string;
  packages?: PackageType[];
  companies?: Company[];
  customerCompanyBranches?: CustomerCompanyBranch[];
  transport?: Transport;
  taskData?: { orderData?: OrderData[], fractions?: Fraction[], value?:number, company: Company, customerCompanyBranch?: CustomerCompanyBranch, customerCompany: Company }[];
  comment?: string;
}
export interface Fraction {
  code: string;
  description: string;
  name: string;
  uuid: string;
}
export class BasicTask {
  taskType: string;
  startDate: string;
  endDate: string;
  name: string;
  companyBranchId: string;
  comment: string;
  referenceNumber?: string;
  registrationNumFront?: string;
  registrationNumBack?: string;
  status?: string;
  taskNumber?: string;
  startTime?: string;
  endTime?: string;
  companyBranch?: CompanyBranch;
  transportId?: string;
  transport?: Transport;
  createdBy?: User;
  uuid?: string;
}

export class Task extends BasicTask {
  taskData: {}[];
}
export class RecurringTaskRequest {
  intervalType: string;
  intervalValue: number;
  rangeDateEnd: string;
  weekdays: {
    Monday: boolean,
    Tuesday: boolean,
    Wednesday: boolean,
    Thursday: boolean,
    Friday: boolean,
    Saturday: boolean,
    Sunday: boolean
  };
  task: Task;
}

export class TaskForwarder {
  id: string;
  companyName: string;
  driverName: string;
  driverPhone: string;
  registrationNumberFront: string;
  registrationNumberBack: string;
  bdo: string;
  carName: string;
  carType: string;
  // expense: number;
  invoice: string;
}

export class TaskExport extends BasicTask {
  taskData: OrderExportSubform[];
  forwarderId: string;
  forwarderCompanyName: string;
  originalCompanyName: string;
  forwarderDriverName: string;
  forwarderDriverPhone: string;
  forwarderRegistrationNumberFront: string;
  forwarderRegistrationNumberBack: string;
  forwarderBdo: string;
  forwarderCarName: string;
  forwarderCarType: string;
  forwarderExpense: number;
  forwarderInvoiceNumber: string;
  forwarderKilometers: number;
  forwarderRouteDescription: string;
}

export class TaskImport extends BasicTask {
  taskData: OrderImportSubform[];
}

export class TaskReceiving extends BasicTask {
  taskData: OrderReceivingSubform[];
  forwarderId: string;
  forwarderCompanyName: string;
  originalCompanyName: string;
  forwarderDriverName: string;
  forwarderDriverPhone: string;
  forwarderRegistrationNumberFront: string;
  forwarderRegistrationNumberBack: string;
  forwarderBdo: string;
  forwarderCarName: string;
  forwarderCarType: string;
  forwarderExpense: number;
  forwarderInvoiceNumber: string;
  forwarderKilometers: number;
  forwarderRouteDescription: string;
}

// SUBFORMS
export class BasicOrderSubform {
  uuid?: string;
  orderType?: string;
  suggestedDate?: string;
  status?: string;
  orderId?: string;
  customerCompanyId?: string;
  referenceNumber: string;
  customerCompany?: FilteredCompany;
  companyBranchId?: string;
  companyBranch?: {
    uuid: string;
    name: string;
  };

}

export class OrderExportSubform extends BasicOrderSubform {
  value: number;
  unit: string;
  transport?: any;
  isReady: boolean;
  customerCompanyBranch: CustomerCompanyBranch;
  fractions?: FilteredFraction[];
  fractionsIds?: string[];
  order?: OrderExport;
}

export class OrderImportSubform extends BasicOrderSubform {
  registrationNumFront: string;
  registrationNumBack: string;
  salesAccepted: boolean;
  orderData: FilteredOrderData[];
  transport?: any;
  bdo: string;
  order?: OrderImport;
}

export class OrderReceivingSubform extends BasicOrderSubform {
  orderData: FilteredOrderData[];
  salesAccepted: boolean;
  availableHoursFrom: string;
  availableHoursTo: string;
  customerCompanyBranch: CustomerCompanyBranch;
  bdo: string;
  bdoFree: boolean;
  bdoPeopleCollection: boolean;
  order?: OrderReceiving;
}

export interface Address {
  city: string;
  street: string;
  postalCode: string;
  country: string;
}

export interface OrderData {
  fractions: Fraction[];
  recyclingItems: RecyclingItem[];
  packageTypes: PackageType[];
  recyclingItem?: RecyclingItem;
}