import {Component, Input, OnInit} from '@angular/core';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-task-pdf-downloader',
  templateUrl: './task-pdf-downloader.component.html',
  styleUrls: ['./task-pdf-downloader.component.scss']
})
export class TaskPdfDownloaderComponent implements OnInit {
  @Input() task: any;
  @Input() branch: any;
  @Input() forwarder: any;
  private receiveHeader = ['auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 36, 36, 38];
  private exportHeader = ['auto', 'auto', 'auto', 'auto', 'auto', 'auto'];
  public buttonLoading = false;

  constructor(
    private translateService: TranslateService
  ) {
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
  }

  ngOnInit() {

  }

  createTable() {
    let body = [];

    const headerRow = [
      {
        text: '',
        style: 'header',
        fillColor: '#000000',
        color: 'white',
        colSpan: 11,
        alignment: 'center'
      },
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      ''
    ];
    const subheaderColTitles = [
      this.translateService.instant('TASKS.DOWNLOAD_PDF.TABLE.COMPANY'),
      this.translateService.instant('TASKS.DOWNLOAD_PDF.TABLE.ADDRESS'),
      this.translateService.instant('TASKS.DOWNLOAD_PDF.TABLE.HOURS'),
      this.translateService.instant('TASKS.DOWNLOAD_PDF.TABLE.CONTACT'),
      this.translateService.instant('TASKS.DOWNLOAD_PDF.TABLE.PACK'),
      this.translateService.instant('TASKS.DOWNLOAD_PDF.TABLE.CARGO'),
      this.translateService.instant('TASKS.DOWNLOAD_PDF.TABLE.WEIGHT'),
      this.translateService.instant('TASKS.DOWNLOAD_PDF.TABLE.COMMENTS'),
      this.translateService.instant('TASKS.DOWNLOAD_PDF.TABLE.BDO'),
      this.translateService.instant('TASKS.DOWNLOAD_PDF.TABLE.PEOPLE_COLLECTION'),
      this.translateService.instant('TASKS.DOWNLOAD_PDF.TABLE.BDO_FREE')
    ];
    const subheaderRow = [];
    // header
    body.push(headerRow);
    // subheader
    subheaderColTitles.map(item => {
      const subheaderItem = {
        text: item,
        style: 'smaller',
        alignment: 'center',
        fillColor: '#999999',
        color: 'white'
      };
      subheaderRow.push(subheaderItem);
    });

    body.push(subheaderRow);
    //task row
    this.task.taskData.forEach(taskDataItem => {
      const taskRow = [
        {
          text: taskDataItem.customerCompanyBranch.name,
          style: 'smaller',
          alignment: 'center'
        },
        {
          text: `${taskDataItem.customerCompanyBranch.street}\n
                ${taskDataItem.customerCompanyBranch.postalCode} ${taskDataItem.customerCompanyBranch.city}`,
          style: 'smaller',
          alignment: 'center'
        },
        {
          text: taskDataItem.order.availableHoursFrom ? `${taskDataItem.order.availableHoursFrom} - ${taskDataItem.order.availableHoursTo}` : '',
          style: 'smaller',
        },
        {
          text: `${taskDataItem.customerCompanyBranch.contact || ''} ${taskDataItem.customerCompanyBranch.phone || ''}`,
          style: 'smaller',
        },
        {
          text: taskDataItem.orderData.map(item => item.packageTypes.map(pack => pack.name).join(',')).join(',\n'),
          style: 'smaller',
          alignment: 'left',
        },
        {
          text: taskDataItem.orderData.map(item => `${item.recyclingItem.name}`).join(',\n'),
          style: 'smaller',
          alignment: 'center'
        },
        {
          text: taskDataItem.order.totalWeight,
          style: 'smaller',
          alignment: 'center'
        },
        {
          text: taskDataItem.order.comment || '',
          style: 'smaller',
          alignment: 'center'
        },
        {
          text: taskDataItem.bdo ? 'V' : 'X',
          style: 'smaller',
          alignment: 'center',
          color: taskDataItem.bdo ? '#007a16' : '#8c0000'
        },
        {
          text: taskDataItem.bdoPeopleCollection ? 'V' : 'X',
          style: 'smaller',
          alignment: 'center',
          color: taskDataItem.bdoPeopleCollection ? '#007a16' : '#8c0000'
        },
        {
          text: taskDataItem.bdoFree ? 'V' : 'X',
          style: 'smaller',
          alignment: 'center',
          color: taskDataItem.bdoFree ? '#007a16' : '#8c0000'
        }
      ];
      body.push(taskRow);
    });


    return body;
  }

  createExportTable() {
    let body = [];

    const headerRow = [
      {
        text: '',
        style: 'header',
        fillColor: '#000000',
        color: 'white',
        colSpan: 6,
        alignment: 'center'
      },
      '',
      '',
      '',
      '',
      ''
    ];
    const subheaderColTitles = [
      this.translateService.instant('TASKS.DOWNLOAD_PDF.TABLE.COMPANY'),
      this.translateService.instant('TASKS.DOWNLOAD_PDF.TABLE.ADDRESS'),
      this.translateService.instant('TASKS.DOWNLOAD_PDF.TABLE.CONTACT'),
      this.translateService.instant('TASKS.DOWNLOAD_PDF.TABLE.FRACTIONS'),
      this.translateService.instant('TASKS.DOWNLOAD_PDF.TABLE.WEIGHT'),
      this.translateService.instant('TASKS.DOWNLOAD_PDF.TABLE.COMMENTS')
    ];
    const subheaderRow = [];
    // header
    body.push(headerRow);
    // subheader
    subheaderColTitles.map(item => {
      const subheaderItem = {
        text: item,
        style: 'smaller',
        alignment: 'center',
        fillColor: '#999999',
        color: 'white'
      };
      subheaderRow.push(subheaderItem);
    });

    body.push(subheaderRow);
    //task row
    this.task.taskData.forEach(taskDataItem => {
      const taskRow = [
        {
          text: taskDataItem.customerCompanyBranch.name,
          style: 'smaller',
          alignment: 'center'
        },
        {
          text: `${taskDataItem.customerCompanyBranch.street}\n
                ${taskDataItem.customerCompanyBranch.postalCode} ${taskDataItem.customerCompanyBranch.city}`,
          style: 'smaller',
          alignment: 'center'
        },
        {
          text: `${taskDataItem.customerCompanyBranch.contact || ''} ${taskDataItem.customerCompanyBranch.phone || ''}`,
          style: 'smaller',
        },
        {
          text: taskDataItem.fractions.map(item => `${item.name} [${item.code}]`).join(',\n'),
          style: 'smaller',
          alignment: 'left',
        },
        {
          text: taskDataItem.value ? taskDataItem.value + ' ' + taskDataItem.unit : '',
          style: 'smaller',
          alignment: 'center'
        },
        {
          text: taskDataItem.order.comment || '',
          style: 'smaller',
          alignment: 'center'
        }
      ];
      body.push(taskRow);
    });


    return body;
  }


  drawTable() {
    return {
      pageOrientation: 'landscape',
      pageMargins: [50, 50, 50, 50],
      content: [
        {
          columns: [
            {
              text: ['Zlecenie transportowe ', {text: this.task.taskNumber, bold: true}],
              fontSize: 12,
              width: 300,
            },
            {
              width: '*',
              text: ''
            },
            {
              width: 300,
              text: ['Typ zlecenia: ', {text: this.task.taskType === 'receiving' ? 'Odbiór' : 'Wywóz', bold: true}],
              fontSize: 8,
            }
          ]
        },
        {
          columns: [{
            width: '*',
            text: ''
          }, {
            width: 300,
            columns: [
              {
                text: 'Data i godzina załadunku/rozładunku: ',
                fontSize: 8,
              },
              {
                text: [this.parseDate(this.task.startDate), '  ', this.parseDateHour(this.task.startDate), ' - ', this.parseDateHour(this.task.endDate)],
                fontSize: 8,
              }
            ]
          }]
        },
        {
          columns: [
            {
              text: ['Data realizacji: ', {
                text: this.parseDate(this.task.taskData[0].order.suggestedDate),
                bold: true
              }],
              width: 300,
              fontSize: 8,
            },
            {
              text: '',
              width: '*'
            },
            {
              width: 300,
              text: 'Miejsce prowadzenia działalności',
              fontSize: 10,
              bold: true,
              background: '#ccc'
            }
          ]
        },
        {
          columns: [
            {
              fontSize: 8,
              text: ['Numer rejestracyjny: ', {
                text: `${this.task.forwarderRegistrationNumberFront || ''} - ${this.task.forwarderRegistrationNumberBack || ''}`,
                bold: true,
              }]
            },
            {
              text: '',
              width: '*'
            },
            {
              width: 300,
              fontSize: 8,
              text: 'REMONDIS Electrorecycling Sp. z o.o.'
            }
          ]
        },
        {
          columns: [
            {
              fontSize: 8,
              text: ['Typ pojazdu: ', {text: this.task.forwarderCarType || '', bold: true}]
            },
            {
              text: '',
              width: '*'
            },
            {
              width: 300,
              fontSize: 8,
              text: this.branch.address.street || ''
            }
          ]
        },
        {
          columns: [
            {
              fontSize: 8,
              text: ['Kierowca: ', {
                text: `${this.task.forwarderDriverName || ''} ${this.task.forwarderDriverPhone ? '-' : ''} ${this.task.forwarderDriverPhone || ''}`,
                bold: true
              }]
            },
            {
              text: '',
              width: '*'
            },
            {
              width: 300,
              fontSize: 8,
              text: `${this.branch.address.postalCode || ''} ${this.branch.address.city || ''}`
            }
          ]
        },
        {
          columns: [
            {
              fontSize: 8,
              text: ['Dodatkowe: ', {text: this.task.comment || 'Brak uwag do zadania'}]
            },
            {
              text: '',
              width: '*'
            },
            {
              width: 300,
              fontSize: 8,
              text: `Nr MPD: ${this.branch.address.mpd || ''}`
            }
          ]
        },
        {
          margin: [0, 30, 0, 0],
          columns: [
            {
              width: 300,
              text: 'Zleceniodawca',
              fontSize: 10,
              bold: true,
              background: '#ccc'
            },
            {
              text: '',
              width: '*'
            },
            {
              width: 300,
              text: 'Przewoźnik',
              fontSize: 10,
              bold: true,
              background: '#ccc'
            }
          ]
        },
        {
          columns: [
            {
              width: 300,
              fontSize: 8,
              text: 'REMONDIS Electrorecycling Sp. z o.o.'
            },
            {
              text: '',
              width: '*'
            },
            {
              width: 300,
              fontSize: 8,
              text: this.forwarder.name || ''
            }
          ]
        },
        {
          columns: [
            {
              width: 300,
              fontSize: 8,
              text: 'Zawodzie 18'
            },
            {
              text: '',
              width: '*'
            },
            {
              width: 300,
              fontSize: 8,
              text: `${this.forwarder.address.street || ''}`
            }
          ]
        },
        {
          columns: [
            {
              width: 300,
              fontSize: 8,
              text: '02-981 Warszawa'
            },
            {
              text: '',
              width: '*'
            },
            {
              width: 300,
              fontSize: 8,
              text: this.forwarder.address.postalCode ? `${this.forwarder.address.postalCode || ''} ${this.forwarder.address.city || ''}` : ''
            }
          ]
        },
        {
          columns: [
            {
              width: 300,
              fontSize: 8,
              text: 'NIP: 631-22-89-539'
            },
            {
              text: '',
              width: '*'
            },
            {
              width: 300,
              fontSize: 8,
              text: this.forwarder.nip ? `NIP: ${this.forwarder.nip || ''}` : ''
            }
          ]
        },
        {
          columns: [
            {
              width: 300,
              fontSize: 8,
              text: 'REGON: 276730380'
            },
            {
              text: '',
              width: '*'
            },
            {
              width: 300,
              fontSize: 8,
              text: this.forwarder.bdo ? `Nr BDO: ${this.forwarder.bdo || ''}` : ''
            }
          ]
        },
        {
          columns: [
            {
              width: 300,
              fontSize: 8,
              text: 'Telefon: 22 755 62 24'
            },
            {
              text: '',
              width: '*'
            },
            {
              width: 300,
              fontSize: 8,
              text: ''
            }
          ]
        },
        {
          columns: [
            {
              width: 300,
              fontSize: 8,
              text: 'Nr BDO: 000002864'
            },
            {
              text: '',
              width: '*'
            }
          ]
        },
        {
          margin: [0, 20, 0, 0],
          text: ''
        },
        {
          table: {
            margin: [20, 20],
            widths: this.task.taskType === 'receiving' ? this.receiveHeader : this.exportHeader,
            body: this.task.taskType === 'receiving' ? this.createTable() : this.createExportTable()
          }
        },
        {
          margin: [0, 20, 0, 0],
          text: ''
        },
        {
          columns: [
            {
              width: 400,
              fontSize: 8,
              text: ['Kategorie zwolnienia z BDO:\n', {
                text: 'ZBIÓRKA',
                bold: true
              }, ' - Nieprofesjonalna działalność w zakresie zbierania odpadów konsumpcyjnych, zgodnie z art. 45' +
              'ust. 1 pkt 1 Ustawy o odpadach, zwolnienie z ewidencji na podstawie art.69 ust.4 ww. ustawy.\n',
                {
                  text: 'ZWOLNIONY',
                  bold: true
                }, ' - odpady zwolnione z ewidencji Rozporządzeniem Ministra Klimatu z dnia 23 grudnia 2019r. w sprawie ' +
                'rodzajów odpadów i ilości odpadów, dla których nie ma obowiązku prowadzenia ewidencji odpadów.']
            },
            {
              text: '',
              width: '*'
            },
          ]
        },
        {
          columns: [
            {
              text: '',
              width: '*'
            },
            {
              width: 150,
              text: {text: `${this.task.createdBy.name || ''} ${this.task.createdBy.surname || ''}`, bold: true},
              fontSize: 10
            }
          ]
        },
        {
          columns: [
            {
              text: '',
              width: '*'
            },
            {
              width: 150,
              text: 'Wystawiający',
              fontSize: 10
            }
          ]
        },
      ],
      styles: {
        header: {
          fontSize: 14,
          bold: true
        },
        note: {
          bold: true,
        },
        smaller: {
          fontSize: 8
        },
        smallerNoBorder: {
          fontSize: 8
        }
      }
    };

  }


  private parseDate(date) {
    if (!date) {
      return '-';
    }
    const selectedDate = new Date(date);
    const year = selectedDate.getFullYear();
    const month = selectedDate.getMonth() + 1;
    const day = selectedDate.getDate();
    return `${year}-${`0${month}`.slice(-2)}-${`0${day}`.slice(-2)}`;
  }

  private parseDateHour(date) {
    const selectedDate = new Date(date);
    const hour = selectedDate.getHours();
    const minutes = selectedDate.getMinutes();
    return `${`0${hour}`.slice(-2)}:${`0${minutes}`.slice(-2)}`;
  }


  getReport() {
    console.log(this.task)
    pdfMake
      .createPdf(this.drawTable())
      .download(`Zlecenie transportowe ${this.task.taskNumber}.pdf`);
  }
}
