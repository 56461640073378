import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatAutocompleteSelectedEvent,
  MatDialog,
  MatDialogRef,
  MatOption,
  MatSnackBar,
  MatStepper
} from '@angular/material';
import {TranslateService} from '@ngx-translate/core';
import {FuseTranslationLoaderService} from '../../../../../../@fuse/services/translation-loader.service';
import {locale as polish} from '../../../../../translations/pl';
import {locale as english} from '../../../../../translations/en';
import {ConfirmModalComponent} from '../../../../shared/components/confirm-modal/confirm-modal.component';
import {AuthService} from '../../../../../services/auth.service';
import {map} from 'rxjs/operators';
import {RequestParams} from '../../../../../shared/models/list.model';
import {Observable, of, zip} from 'rxjs';
import {OrderExportSubform, TaskExport, TaskStatuses, TaskTypes} from '../../../../../shared/models/tasks.model';
import {FilteredCompany, FilteredFraction, FilteredTransport} from '../../../../../shared/models/filtered-items.model';
import {CompanyBranch} from '../../../../../shared/models/company.model';
import {UsersService} from '../../../../../services/users.service';
import {FilteredListsService} from '../../../../../services/filtered-lists.service';
import {TasksService} from '../../../../../services/tasks.service';
import * as moment from 'moment';
import {EndTimeOptions, StartTimeOptions} from '../../../../../shared/consts/tasks';
import {OrderExport} from '../../../../../shared/models/orders.model';
import {StartEndDateValidator} from '../../../../../shared/validators/startEndDate.validator';
import {CustomerCompanyBranch} from '../../../../../shared/models/company-branches.model';
import {getDaysInMonth} from '../../../../../shared/utils/custom-date-formatter';
import {User} from 'app/shared/models/users.model';
import {CompaniesService} from '../../../../../services/companies.service';
import {CompanyCarsService} from '../../../../../services/company-cars.service';
import {CustomerCompanyCar} from "../../../../../shared/models/company-cars.model";

@Component({
  selector: 'app-task-export-form',
  templateUrl: './task-export-form.component.html',
  styleUrls: ['./task-export-form.component.scss']
})
export class TaskExportFormComponent implements OnInit {
  @ViewChild('companySelect') companySelect;
  @ViewChild('stepper') stepper: MatStepper;
  isExpanded: boolean[] = [];
  public indexStep: number;
  public addInvoiceEnabled: false;
  public taskExportForm: FormGroup;
  public forwarderForm: FormGroup;
  public taskId: TaskExport['uuid'] | TaskExport['taskNumber'];
  public task: TaskExport;
  public customerCompanyBranches: {
    [key: string]: CustomerCompanyBranch[];
  } = {};
  public taskStatuses = TaskStatuses;
  public taskTypes = TaskTypes;
  public fractionOptions: FilteredFraction[] = [];
  public transportOptions: FilteredTransport[] = [];
  public customerCompanyOptions: FilteredCompany[] = [];
  public forwarderCompanies: FilteredCompany[] = [];
  public companyCars: CustomerCompanyCar[] = [];
  public companyBranches: CompanyBranch[] = [];
  public ordersOptions: OrderExport[] = [];
  public ordersOptionsInitial: string[] = [];
  public ordersOptionsRemoved: OrderExport[] = [];
  public startTimeOptions = StartTimeOptions;
  public endTimeOptions = EndTimeOptions;
  public mode: any;
  public user: User;
  public isLoading = false;
  public searchCustomerCompany: FormControl;
  public searchTransport: FormControl;
  public searchFractions: FormControl;
  public searchForwarder: FormControl;
  public companyCarSelect: FormControl;
  public forwarderIdSelect: FormControl;
  public searchOrders: FormControl;
  public todayDate: Date;
  public rangeDateEndMax: Date;
  public rangeDateEndMin: Date;
  public pdfBranch: any;
  public pdfForwarder: any;
  private filterParams: RequestParams = {
    page: 1,
    limit: 20,
    sort: 'name',
    order: 'asc'
  };
  private filterParamsFullList: RequestParams = {
    page: 1,
    limit: 1000,
    sort: 'name',
    order: 'asc',
    active: 'true'
  };
  private filterOrdersParams: RequestParams = {
    page: 1,
    limit: 20,
    sort: 'createdDate',
    order: 'asc',
    available: true,
    orderType: this.taskTypes.Export,
    task: false,
    isReady: true
  };
  private filterForwarderParams: RequestParams = {
    page: 1,
    limit: 20,
    sort: 'createdDate',
    order: 'asc',
    companyType: 'forwarder'
  };

  constructor(
    public taskEditorDialogRef: MatDialogRef<TaskExportFormComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private matDialog: MatDialog,
    private authService: AuthService,
    private tasksService: TasksService,
    private companiesService: CompaniesService,
    private companyCarsService: CompanyCarsService,
    private filteredListsService: FilteredListsService,
    private usersService: UsersService,
    private translateService: TranslateService,
    private snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    private _fuseTranslationLoaderService: FuseTranslationLoaderService
  ) {
    this.user = this.authService.user.user;
    this.mode = data.mode;
    this.taskId = data.taskId || data.taskNumber;
    this._fuseTranslationLoaderService.loadTranslations(polish, english);
    this.indexStep = data.indexStep || 0;

  }

  ngOnInit() {
    this.createSearchFilters();
    this.createForm();

    this.tasksService
      .getTask(this.taskId).subscribe(res => {
      this.task = res;
      this.taskId = res.uuid;
      this.usersService.getCompanyBranchList().subscribe(response => {
        this.companyBranches = response.branches;
      });
      res.taskData.forEach(item => {
        if (item.order && item.order.uuid) {
          this.ordersOptionsInitial.push(item.order.uuid);
        }
      });
      this.createForm(this.task);
      this.getPdfNeededData();
    });
  }

  private setRangeEndDateMax(date: Date) {
    this.rangeDateEndMin = new Date(
      this.taskExportForm.get('startDate').value || this.todayDate
    );
    this.rangeDateEndMax = new Date(date || this.todayDate);
    this.rangeDateEndMax.setMonth(
      this.rangeDateEndMax.getMonth() !== 11
        ? this.rangeDateEndMax.getMonth() + 1
        : 0
    );
    if (this.rangeDateEndMax.getMonth() === 0) {
      this.rangeDateEndMax.setFullYear(this.rangeDateEndMax.getFullYear() + 1);
    }
    this.rangeDateEndMax.setDate(
      getDaysInMonth(
        this.rangeDateEndMax.getFullYear(),
        this.rangeDateEndMax.getMonth()
      )
    );
  }

  public setStartDate(newDate) {
    this.taskExportForm.patchValue({endDate: new Date(newDate)});
    this.setRangeEndDateMax(newDate);
  }

  public addOrderSubform(
    index: number,
    orderItem: OrderExportSubform = new OrderExportSubform(),
    generatedFromOrderId = false
  ) {
    Object.keys(this.isExpanded).forEach(
      item => (this.isExpanded[item] = false)
    );
    this.isExpanded.push(!orderItem.value);
    const control = <FormArray>this.taskExportForm.controls.taskData;
    control.push(
      this.formBuilder.group({
        uuid: new FormControl(
          this.mode === 'clone'
            ? 'other'
            : (generatedFromOrderId && orderItem.uuid) ||
            (orderItem.order && orderItem.order.uuid) ||
            orderItem.orderId ||
            'other'
        ),
        order: new FormControl(
          this.mode === 'clone'
            ? {uuid: 'other'}
            : (generatedFromOrderId && orderItem) ||
            orderItem.order || {uuid: 'other'}
        ),
        customerCompany: new FormControl(
          orderItem.customerCompany || null,
          Validators.required
        ),
        customerCompanyBranch: new FormControl(
          orderItem.customerCompanyBranch
            ? orderItem.customerCompanyBranch.uuid
            ? orderItem.customerCompanyBranch.uuid
            : 'other'
            : null,
          Validators.required
        ),
        transport: new FormControl(
          orderItem.order && orderItem.order.transport || orderItem.transport || null,
          Validators.required
        ),
        isReady: new FormControl(orderItem.order && orderItem.order.isReady || orderItem.isReady || false, Validators.required),
        suggestedDate: new FormControl(
          this.mode === 'clone' ? null : orderItem.order && orderItem.order.suggestedDate || orderItem.suggestedDate || null
        ),
        fractions: new FormControl(
          orderItem.fractions || null,
          Validators.required
        ),
        value: new FormControl(orderItem.value || null, [
          Validators.required,
          Validators.pattern('[\\d]+[.]{0,1}[\\d]{0,3}')
        ]),
        unit: new FormControl(orderItem.unit || 'kg', Validators.required),
        referenceNumber: new FormControl(
          this.mode === 'clone' ? null : orderItem.referenceNumber || null
        ),
        customerCompanyBranchOther: new FormGroup({
          name: new FormControl(
            (orderItem.customerCompanyBranch &&
              orderItem.customerCompanyBranch.name) ||
            null,
            orderItem.customerCompanyBranch && orderItem.customerCompanyBranch.name ? Validators.required : null
          ),
          street: new FormControl(
            (orderItem.customerCompanyBranch &&
              orderItem.customerCompanyBranch.street) ||
            null,
            Validators.required
          ),
          postalCode: new FormControl(
            (orderItem.customerCompanyBranch &&
              orderItem.customerCompanyBranch.postalCode) ||
            null,
            Validators.required
          ),
          city: new FormControl(
            (orderItem.customerCompanyBranch &&
              orderItem.customerCompanyBranch.city) ||
            null,
            Validators.required
          ),
          country: new FormControl(
            (orderItem.customerCompanyBranch &&
              orderItem.customerCompanyBranch.country) ||
            'Polska',
            Validators.required
          ),
          contact: new FormControl(
            (orderItem.customerCompanyBranch &&
              orderItem.customerCompanyBranch.contact) ||
            null
          ),
          email: new FormControl(
            (orderItem.customerCompanyBranch &&
              orderItem.customerCompanyBranch.email) ||
            null, Validators.email
          ),
          phone: new FormControl(
            (orderItem.customerCompanyBranch &&
              orderItem.customerCompanyBranch.phone) ||
            null,
            Validators.required
          ),
          note: new FormControl(
            (orderItem.customerCompanyBranch &&
              orderItem.customerCompanyBranch.note) ||
            null
          ),
          order_comment: new FormControl((orderItem.order && orderItem.order.comment) || null),
          mainBranch: new FormControl(
            (orderItem.customerCompanyBranch &&
              orderItem.customerCompanyBranch.mainBranch) ||
            false
          )
        })
      })
    );
    generatedFromOrderId &&
    control
      .at(index)
      .get('order')
      .disable();
    generatedFromOrderId &&
    setTimeout(() => this.taskExportForm.get('companyBranchId').disable(), 0);
  }

  public compareObjectsByUuid(val1, val2): boolean {
    return val1 && val2 && val1.uuid && val2.uuid && val1.uuid === val2.uuid;
  }

  public getFractionsOptions(index: number) {
    const options = [];
    const selectedOrder = this.taskExportForm.get('taskData').value[index];
    if (selectedOrder.fractions) {
      selectedOrder.fractions.forEach(fraction => {
        if (options.findIndex(item => item.uuid === fraction.uuid) === -1) {
          options.push(fraction);
        }
      });
    }
    return options;
  }

  public getOrdersOptions(index: number) {
    const options = [];
    const selectedOrder = this.taskExportForm.get('taskData').value[index];
    this.taskExportForm.get('taskData').value &&
    this.taskExportForm.get('taskData').value.forEach(chosenOrder => {
      if (
        chosenOrder.uuid &&
        chosenOrder.uuid !== 'other' &&
        options.findIndex(item => item.uuid === chosenOrder.uuid) > -1 &&
        chosenOrder.uuid !== selectedOrder.uuid
      ) {
        options.splice(
          options.findIndex(item => item.uuid === chosenOrder.uuid),
          1
        );
      }
    });
    if (
      selectedOrder.uuid &&
      options.findIndex(order => order.uuid === selectedOrder.uuid) === -1 &&
      selectedOrder.uuid !== 'other'
    ) {
      options.push(selectedOrder.order || selectedOrder);
    }
    return options;
  }

  public getCustomerCompaniesOptions(index: number) {
    const options = [];
    const selectedOrder = this.taskExportForm.get('taskData').value[index];
    if (
      selectedOrder.customerCompany &&
      selectedOrder.customerCompany.uuid &&
      options.findIndex(
        item => item.uuid === selectedOrder.customerCompany.uuid
      ) === -1
    ) {
      options.push(selectedOrder.customerCompany);
    }
    return options;
  }

  public getTransportOptions() {
    const options = [];
    const chosenTransport = this.taskExportForm.get('transport').value;
    if (
      chosenTransport &&
      options.findIndex(item => item.uuid === chosenTransport.uuid) === -1
    ) {
      options.push(chosenTransport);
    }
    return options;
  }

  private createForm(inputData: TaskExport = new TaskExport()) {
    inputData.startTime = inputData.startDate
      ? moment(inputData.startDate).format('HH:mm')
      : null;
    inputData.endTime = inputData.endDate
      ? moment(inputData.endDate).format('HH:mm')
      : null;

    this.taskExportForm = this.formBuilder.group(
      {
        name: new FormControl(inputData.name || null, Validators.required),
        companyBranchId: new FormControl(
          (inputData.companyBranch && inputData.companyBranch.uuid) ||
          this.data.companyBranchId ||
          null,
          Validators.required
        ),
        transport: new FormControl(
          inputData.transport || null,
          Validators.required
        ),
        startDate: new FormControl(
          this.mode === 'clone'
            ? null
            : inputData.startDate || this.data.startDate || null,
          Validators.required
        ),
        startTime: new FormControl(
          inputData.startTime || this.data.startTime || null,
          Validators.required
        ),
        endDate: new FormControl(
          this.mode === 'clone'
            ? null
            : inputData.endDate || this.data.endDate || null,
          Validators.required
        ),
        endTime: new FormControl(
          inputData.endTime || this.data.endTime || null,
          Validators.required
        ),
        taskType: new FormControl(
          inputData.taskType || this.taskTypes.Export,
          Validators.required
        ),
        comment: new FormControl(
          this.mode === 'add' || this.mode === 'clone'
            ? null
            : inputData.comment || null
        ),
        referenceNumber: new FormControl(
          this.mode === 'add' || this.mode === 'clone'
            ? null
            : inputData.referenceNumber || null
        ),
        registrationNumFront: new FormControl(
          this.mode === 'add' ? null : (inputData.forwarderRegistrationNumberFront ?
            inputData.forwarderRegistrationNumberFront : inputData.registrationNumFront || null)
        ),
        registrationNumBack: new FormControl(
          this.mode === 'add' ? null : (inputData.forwarderRegistrationNumberBack ?
            inputData.forwarderRegistrationNumberBack : inputData.registrationNumBack || null)
        ),
        taskData: this.formBuilder.array([], Validators.required),
        recurring: new FormControl(false),
        recurringInfo: this.formBuilder.group({
          intervalType: new FormControl('WEEK'),
          intervalValue: new FormControl(1, Validators.pattern(/\d/)),
          rangeDateEnd: new FormControl(inputData.startDate),
          weekdays: new FormGroup({
            Monday: new FormControl(false),
            Tuesday: new FormControl(false),
            Wednesday: new FormControl(false),
            Thursday: new FormControl(false),
            Friday: new FormControl(false),
            Saturday: new FormControl(false),
            Sunday: new FormControl(false)
          })
        }),
        forwarderId: new FormControl(inputData.forwarderId || ''),
        forwarderCompanyName: new FormControl(inputData.forwarderCompanyName || ''),
        originalCompanyName: new FormControl(inputData.originalCompanyName || ''),
        forwarderDriverName: new FormControl(inputData.forwarderDriverName || ''),
        forwarderDriverPhone: new FormControl(inputData.forwarderDriverPhone || ''),
        forwarderRegistrationNumberFront: new FormControl(inputData.forwarderRegistrationNumberFront || ''),
        forwarderRegistrationNumberBack: new FormControl(inputData.forwarderRegistrationNumberBack || ''),
        forwarderBdo: new FormControl(inputData.forwarderBdo || ''),
        forwarderCarName: new FormControl(inputData.forwarderCarName || ''),
        forwarderCarType: new FormControl(inputData.forwarderCarType || ''),
        forwarderExpense: new FormControl(inputData.forwarderExpense),
        forwarderInvoiceNumber: new FormControl(inputData.forwarderInvoiceNumber || '')
      },
      {
        validator: StartEndDateValidator.CheckDates
      }
    );
    inputData.taskData &&
    inputData.taskData.forEach((item, index) => this.addOrderSubform(index, item));
    this.setRangeEndDateMax(this.taskExportForm.get('startDate').value);

    if (this.mode === 'add' || this.mode === 'clone') {
      this.taskExportForm.get('companyBranchId').disable();
    }
    // this.searchForwarder.setValue(inputData.originalCompanyName || '');
    if (this.mode === 'view') {
      this.taskExportForm.disable();
    }
    if (inputData.forwarderId) {
      this.getCompanyCars(inputData.forwarderId);
    }
    this.forwarderForm =  this.formBuilder.group({
      id: new FormControl(inputData.forwarderId || ''),
      companyName: new FormControl(inputData.forwarderCompanyName || ''),
      driverName: new FormControl(inputData.forwarderDriverName || ''),
      driverPhone: new FormControl(inputData.forwarderDriverPhone || ''),
      registrationNumberFront: new FormControl(inputData.forwarderRegistrationNumberFront || ''),
      registrationNumberBack: new FormControl(inputData.forwarderRegistrationNumberBack || ''),
      forwarderKilometers: new FormControl(inputData.forwarderKilometers ? String(inputData.forwarderKilometers).replace('.', ',') : ''),
      forwarderRouteDescription: new FormControl(inputData.forwarderRouteDescription || ''),
      bdo: new FormControl(inputData.forwarderBdo || ''),
      carName: new FormControl(inputData.forwarderCarName || ''),
      carType: new FormControl(inputData.forwarderCarType || ''),
      // expense: new FormControl(inputData.forwarderExpense || null),
      invoice: new FormControl(inputData.forwarderInvoiceNumber || '')
    });
    if (this.task && this.task.status.toUpperCase() !== 'NOTIFIED' && this.task.status.toUpperCase() !== 'SEND_TO_FORWARDER'){
      this.forwarderForm.disable();
    }

  }

  private mapTaskData(taskData: OrderExportSubform[]) {
    taskData = taskData.map(item => {
      const taskDataItem = {
        customerCompanyBranch: item.customerCompanyBranch,
        customerCompanyId: item.customerCompany
          ? item.customerCompany.uuid
          : null,
        referenceNumber: item.referenceNumber || null,
        transport: item.transport,
        suggestedDate: item.suggestedDate,
        isReady: item.isReady,
        order: item.order,
        fractionsIds:
          (item.fractions && item.fractions.map(fraction => fraction.uuid)) ||
          [],
        unit: item.unit || 'kg',
        value: item.value
      };
      if (item.uuid !== 'other') {
        taskDataItem['orderId'] = item.uuid;
      }
      return taskDataItem;
    });
    return taskData;
  }

  public changeStep(event) {
    this.indexStep = event.selectedIndex;
  }

  public prevStep(event) {
    this.indexStep = this.indexStep - 1 >= 0 ? this.indexStep - 1 : 0;
  }

  public getFormCompanyUuid(i: number): string {
    const control = <FormArray>this.taskExportForm.controls.taskData;
    const group = <FormGroup>control.controls[i];
    return (
      (group.get('customerCompany').value &&
        group.get('customerCompany').value.uuid) ||
      '-'
    );
  }

  public getTooltip(branch: any) {
    return `${branch.name}
    ${branch.street}
    ${branch.postalCode} ${branch.city}`;
  }

  public getTooltipCompany(company: any) {
    if (company) {
      return `${company.name} 
      ${company.address.street}
      ${company.address.postalCode} ${company.address.city}`;
    }
  }

  private createSearchFilters() {
    this.companyCarSelect = new FormControl('');
    this.companyCarSelect.valueChanges.subscribe((companyCar) => {
      this.forwarderForm.patchValue({
        companyName: companyCar.companyName,
        driverName: companyCar.driverName,
        driverPhone: companyCar.driverPhone,
        registrationNumberFront: companyCar.registrationNumberFront,
        registrationNumberBack: companyCar.registrationNumberBack,
        bdo: companyCar.bdo,
        carName: companyCar.name,
        carType: companyCar.type
      });
    });
  }

  public submitForm() {
    this.isLoading = true;
    const data = this.forwarderForm.getRawValue();
    // data.expense = Number(data.expense);
    this.tasksService.updateTask(this.taskId, data ).subscribe( res => {
      this.isLoading = false;
      this.taskEditorDialogRef.close();
      this.snackBar.open(this.translateService.instant('TASKS.MESSAGES.UPDATE_SUCCESS'), '', {duration: 5000});
    }, error => {
      this.isLoading = false;
      this.snackBar.open(this.translateService.instant('TASKS.MESSAGES.UPDATE_ERROR'), '', {duration: 5000});
    });
  }

  private getCompanyCars(forwarderId: string) {
    this.companyCarsService.getCompanyCarsList(this.filterParamsFullList, forwarderId).subscribe(
      response => {
        this.companyCars = response.cars;
      });
  }


  private getPdfNeededData() {
    this.usersService.getCompanyBranchList().subscribe(res => {
      this.pdfBranch = res.branches.find(item => item.uuid === this.task.companyBranch.uuid);
    });
    if (this.task.forwarderId) {
      this.companiesService.getCompany(this.task.forwarderId).subscribe(res => {
        this.pdfForwarder = res;
      });
    } else {
      this.pdfForwarder = {name: 'Brak Danych', address: 'Brak danych'};
    }
  }
}
