import { NgModule } from '@angular/core';
import { NoAccessComponent } from './components/no-access/no-access.component';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { FuseSharedModule } from '../../../@fuse/shared.module';
import {
  MatButtonModule,
  MatCheckboxModule,
  MatDialogModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatPaginatorModule,
  MatProgressSpinnerModule,
  MatSnackBarModule,
  MatSortModule,
  MatTableModule,
  MatSelectModule,
  MatCardModule,
  MatDividerModule,
  MatToolbarModule,
  MatTooltipModule,
  MatDatepickerModule,
  MatSlideToggleModule,
  MatButtonToggleModule,
  MatListModule,
  MAT_DATE_LOCALE,
  MatRadioModule,
  MatAutocompleteModule,
  MatExpansionModule,
  MatTabsModule,
  MatChipsModule
} from '@angular/material';
import { AuthGuard } from '../../services/guards/auth.guard';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoginComponent } from './components/login/login.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { SelectCompanyComponent } from './components/select-company/select-company.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { ListFiltersComponent } from './components/list-filters/list-filters.component';
import { ConfirmModalComponent } from './components/confirm-modal/confirm-modal.component';
import { AvatarModule } from 'ng2-avatar';
import { CommonModule } from '@angular/common';
import { FileDropModule } from 'ngx-file-drop';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import {TaskPdfDownloaderComponent} from "./components/task-pdf-downloader/task-pdf-downloader.component";
import {ItemsListPickerFormComponent} from "./components/items-list-picker-form/items-list-picker-form.component";


const routes = [
  {
    path: 'no-access',
    component: NoAccessComponent,
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    FuseSharedModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    MatRadioModule,
    MatPaginatorModule,
    MatSortModule,
    MatTableModule,
    MatDialogModule,
    MatSelectModule,
    MatIconModule,
    MatCardModule,
    MatDividerModule,
    MatToolbarModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatSlideToggleModule,
    MatButtonToggleModule,
    MatAutocompleteModule,
    MatListModule,
    MatExpansionModule,
    MatTabsModule,
    AvatarModule,
    CommonModule,
    FileDropModule,
    NgxMatSelectSearchModule,
    MatChipsModule
  ],
  declarations: [
    NoAccessComponent,
    ItemsListPickerFormComponent,
    LoginComponent,
    ForgotPasswordComponent,
    SelectCompanyComponent,
    ChangePasswordComponent,
    ListFiltersComponent,
    ConfirmModalComponent,
    TaskPdfDownloaderComponent,
  ],
  exports: [
    FuseSharedModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    AvatarModule,
    CommonModule,
    ItemsListPickerFormComponent,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    MatRadioModule,
    MatPaginatorModule,
    MatSortModule,
    MatTableModule,
    MatDialogModule,
    MatSelectModule,
    MatIconModule,
    MatToolbarModule,
    MatCardModule,
    MatDividerModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatSlideToggleModule,
    MatButtonToggleModule,
    MatListModule,
    MatAutocompleteModule,
    MatExpansionModule,
    MatTabsModule,
    NoAccessComponent,
    LoginComponent,
    NoAccessComponent,
    ForgotPasswordComponent,
    SelectCompanyComponent,
    ChangePasswordComponent,
    ConfirmModalComponent,
    ListFiltersComponent,
    FileDropModule,
    NgxMatSelectSearchModule,
    TaskPdfDownloaderComponent,
  ],
  entryComponents: [
    ConfirmModalComponent,
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'pl-PL' }
  ]
})
export class SharedModule {
}
