import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { RequestParams } from '../shared/models/list.model';
import { AuthService } from './auth.service';
import { CustomerCompanyCar, CompanyCarsListRespone } from '../shared/models/company-cars.model';
import { Company } from '../shared/models/companies.model';

@Injectable({
  providedIn: 'root'
})
export class CompanyCarsService {
  onEditEvent: EventEmitter<any> = new EventEmitter();

  constructor(private http: HttpClient, private authService: AuthService) {
  }

  getCompanyCarsList(params: RequestParams, companyUuid: Company['uuid']): Observable<CompanyCarsListRespone> {
    return this.http.get(`${environment.apiUrl}/${this.authService.getUserCompanyId()}/cars`,
      {params: params}).pipe(map((res: CompanyCarsListRespone) => res));
  }

  getCompanyCar(companyUuid: Company['uuid'], companyCarUuid: CustomerCompanyCar['uuid']): Observable<CustomerCompanyCar> {
    return this.http.get(`${environment.apiUrl}/${this.authService.getUserCompanyId()}/cars/${companyCarUuid}`)
      .pipe(map((res: any) => res.car));
  }

  createCompanyCar(companyUuid: Company['uuid'], car: CustomerCompanyCar): Observable<CustomerCompanyCar> {
    return this.http.post<any>(`${environment.apiUrl}/${this.authService.getUserCompanyId()}/cars`, car)
      .pipe(map((res: any) => res.car));
  }

  updateCompanyCar(companyUuid: Company['uuid'], carUuid: CustomerCompanyCar['uuid'], car: CustomerCompanyCar): Observable<CustomerCompanyCar> {
    return this.http.post<any>(`${environment.apiUrl}/${this.authService.getUserCompanyId()}/cars/${carUuid}`, car);
  }

  deleteCompanyCar(companyUuid: Company['uuid'], carUuid: CustomerCompanyCar['uuid']) {
    return this.http.delete<any>(`${environment.apiUrl}/${this.authService.getUserCompanyId()}/cars/${carUuid}`,
    );
  }
}
