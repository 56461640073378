var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { CookieService } from 'ngx-cookie';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { tap } from 'rxjs/operators';
import * as moment from 'moment';
import { NavigationService } from '../navigation/navigation.service';
import { removeAccents } from '../shared/utils/remove-accents';
import { MatSnackBar } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material';
import * as i0 from "@angular/core";
import * as i1 from "ngx-cookie";
import * as i2 from "@angular/material/dialog";
import * as i3 from "../navigation/navigation.service";
import * as i4 from "@angular/router";
import * as i5 from "@angular/common/http";
import * as i6 from "@angular/material/snack-bar";
import * as i7 from "@ngx-translate/core";
var AuthService = /** @class */ (function () {
    function AuthService(cookieService, dialogRef, navigationService, router, http, snackBar, translateService) {
        this.cookieService = cookieService;
        this.dialogRef = dialogRef;
        this.navigationService = navigationService;
        this.router = router;
        this.http = http;
        this.snackBar = snackBar;
        this.translateService = translateService;
        this.authCookieName = 'REMONDIS-auth';
        this.companyCookieName = 'REMONDIS-userCompany';
        if (this.cookieService.getObject(this.authCookieName)) {
            this._user = this.cookieService.getObject(this.authCookieName);
        }
    }
    AuthService.prototype.login = function (loginData) {
        var _this = this;
        return this.http.post(environment.apiUrl + "/user/session", {
            email: loginData.email,
            password: loginData.password
        }).pipe(tap(function (response) {
            response.user.avatarName = removeAccents(response.user.name + " " + response.user.surname);
            _this.updateSession(response);
            _this.navigationService.navigation(response.user.userType, true);
            _this.router.navigate(['/select-company']);
            _this.intervalHandler = setInterval(function () {
                if (!_this.cookieService.getObject(_this.authCookieName)) {
                    _this._user = null;
                    _this.router.navigate(['/login']);
                    _this.snackBar.open(_this.translateService.instant('SHARED.MESSAGES.SESSION_EXPIRED'), '', { duration: 10000 });
                    clearInterval(_this.intervalHandler);
                    _this.dialogRef.closeAll();
                }
            }, 60000);
        }));
    };
    AuthService.prototype.logout = function () {
        var _this = this;
        if (this._user) {
            return this.http.delete(environment.apiUrl + "/user/session").subscribe(function () {
                _this.removeAuthData();
                _this._user = null;
                _this.router.navigate(['/login']);
                clearInterval(_this.intervalHandler);
            }, function () {
                _this.removeAuthData();
                _this._user = null;
                _this.router.navigate(['/login']);
                clearInterval(_this.intervalHandler);
            });
        }
    };
    AuthService.prototype.getUserCompanies = function () {
        return this.http.get(environment.apiUrl + "/companies");
    };
    AuthService.prototype.setUserCompanyId = function (id) {
        this.setCookie(this.companyCookieName, { id: id });
        this._userCompanyId = id;
    };
    AuthService.prototype.getUserCompanyId = function () {
        if (!this._userCompanyId) {
            var idObj = this.cookieService.getObject(this.companyCookieName);
            this.setUserCompanyId(idObj.id);
            return idObj.id;
        }
        return this._userCompanyId;
    };
    AuthService.prototype.forgotPassword = function (data) {
        return this.http.post(environment.apiUrl + "/user/forgot-password", data);
    };
    AuthService.prototype.setPassword = function (data) {
        return this.http.post(environment.apiUrl + "/user/set-password", data);
    };
    AuthService.prototype.getUser = function () {
        if (this._user && this._user.user) {
            return this._user.user;
        }
    };
    AuthService.prototype.getUserType = function () {
        if (this._user && this._user.user) {
            return this._user.user.userType;
        }
    };
    AuthService.prototype.updateSession = function (user) {
        this.setCookie(this.authCookieName, user);
        this._user = user;
    };
    Object.defineProperty(AuthService.prototype, "user", {
        get: function () {
            return __assign({}, this._user);
        },
        set: function (x) {
            throw new Error('Cannot change user');
        },
        enumerable: true,
        configurable: true
    });
    AuthService.prototype.setCookie = function (cookieName, data) {
        var expirationDate = moment().add(29, 'minutes').toDate();
        this.cookieService.putObject(cookieName, data, { path: '/', expires: expirationDate });
    };
    AuthService.prototype.removeAuthData = function () {
        this.cookieService.remove(this.authCookieName, { path: '/' });
        this.cookieService.remove(this.companyCookieName, { path: '/' });
        this._user = null;
    };
    AuthService.ngInjectableDef = i0.defineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.inject(i1.CookieService), i0.inject(i2.MatDialog), i0.inject(i3.NavigationService), i0.inject(i4.Router), i0.inject(i5.HttpClient), i0.inject(i6.MatSnackBar), i0.inject(i7.TranslateService)); }, token: AuthService, providedIn: "root" });
    return AuthService;
}());
export { AuthService };
