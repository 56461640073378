import {EventEmitter, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {RequestParams} from '../shared/models/list.model';
import {AuthService} from './auth.service';
import {Task, TaskForwarder, TasksListResponse} from '../shared/models/tasks.model';
import {environment} from '../../environments/environment';
import {map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class TasksService {
    onEditEvent: EventEmitter<any> = new EventEmitter();

    constructor(private http: HttpClient, private authService: AuthService) {
    }

    getTasksList(params: RequestParams): Observable<TasksListResponse> {
        return this.http.get(`${environment.apiUrl}/${this.authService.getUserCompanyId()}/tasks`, {params: params})
            .pipe(map((res: TasksListResponse) => res));
    }

    getTasksListByDay(date: string, companyBranchId: any): Observable<any> {
        const params = {
            dateFrom: date,
            dateTo: date,
            status: 'new',
            companyBranchId
        };
        return this.http.get(`${environment.apiUrl}/${this.authService.getUserCompanyId()}/tasks`, {params})
            .pipe(map((res: TasksListResponse) => res));
    }

    getTask(taskId: Task['uuid'] | Task['taskNumber']): Observable<any> {
        return this.http.get(`${environment.apiUrl}/${this.authService.getUserCompanyId()}/tasks/${taskId}`).pipe(
            map((res: any) => res.task)
        );
    }


    updateTask(taskId: Task['uuid'], taskForwarder: TaskForwarder): Observable<Task> {
        return this.http.put(`${environment.apiUrl}/${this.authService.getUserCompanyId()}/tasks/${taskId}`, taskForwarder).pipe(
            map((res: any) => res.task)
        );
    }

}