import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../../../services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material';
import { FuseConfigService } from '../../../../../@fuse/services/config.service';
import { FuseTranslationLoaderService } from '../../../../../@fuse/services/translation-loader.service';
import { locale as polish } from '../../../../translations/pl';
import { PasswordValidator } from '../../../../shared/validators/password.validator';
import {CompaniesResponse} from "../../../../shared/models/userCompany.model";

@Component({
  selector: 'app-select-company',
  templateUrl: './select-company.component.html',
  styleUrls: ['./select-company.component.scss']
})
export class SelectCompanyComponent implements OnInit {
  public selectCompanyForm: FormGroup;
  public isLoading = false;
  public companies = [];

  constructor(private authService: AuthService,
              private translateService: TranslateService,
              private router: Router,
              private route: ActivatedRoute,
              private snackBar: MatSnackBar,
              private formBuilder: FormBuilder,
              private _fuseConfigService: FuseConfigService,
              private _fuseTranslationLoaderService: FuseTranslationLoaderService) {
    // Configure the layout
    this._fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },
        toolbar: {
          hidden: true
        },
        footer: {
          hidden: true
        },
        sidepanel: {
          hidden: true
        }
      }
    };

    this._fuseTranslationLoaderService.loadTranslations(polish);
  }

  ngOnInit() {
    this.getCompanies();
    this.selectCompanyForm = this.formBuilder.group({
      companyId: ['', Validators.required]
    });
  }

  public getCompanies() {
    this.authService.getUserCompanies().subscribe(
      (res: CompaniesResponse) => {
        this.isLoading = false;
       this.companies = res.companies;
       if (res.companies.length === 1) {
         this.authService.setUserCompanyId(res.companies[0].uuid);
         this.router.navigate([ '/tasks' ]);
       }
      }, () => {
        this.isLoading = false;
        this.snackBar.open(this.translateService.instant('SHARED.MESSAGES.ERROR'), '', {duration: 5000});
      }
    );
  }

  public submit() {
    this.isLoading = true;
    this.authService.setUserCompanyId(this.selectCompanyForm.get('companyId').value);
    this.router.navigate([ '/tasks' ]);
  }
}
