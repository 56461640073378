<div class="tasks">
  <div class="header p-16 p-sm-24"
       fxLayout.gt-xs="row"
       fxLayoutAlign.gt-xs="space-between center" *ngIf="user">
    <div fxLayout="row" fxLayoutAlign="start center">
      <div class="logo" fxLayout="row" fxLayoutAlign="start center">
        <mat-icon class="logo-icon mr-16" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
          style
        </mat-icon>
        <span class="logo-text h1" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
          {{'TASKS.TITLE_LIST' | translate}}
        </span>
      </div>
    </div>
    <div class="search-wrapper">
      <div class="search">
        <label for="search" class="mr-8">
          <mat-icon (click)="clearSearch()">{{searchTask.value ? 'clear' : 'search'}}</mat-icon>
        </label>
        <input id="search" [formControl]="searchTask" [placeholder]="'TASKS.SEARCH_PLACEHOLDER' | translate">
      </div>
    </div>
  </div>
   <div class="tasks-content">
    <div class="container-pdf-filter">
      <app-list-filters class="filters-container"
                        [filters]="tableFilters"
                        [tasks]="true"
                        [customMultiFilters]="[{name: 'companyBranchId', options: companyBranches}]"
                        [initFilters]="initialUserFilters"
                        [dateFilters]="['dateFrom', 'dateTo']"
                        (listFilters)="getListFilters($event)"
                        [companyBranchId]="companyBranchId"
                        [companyBranchName]="companyBranchName">
      </app-list-filters>
    </div>
    <div class="tasks-table">
      <mat-table
        matSort
        [dataSource]="dataSource"
        [matSortActive]="requestParams.sort"
        [matSortDirection]="requestParams.task"
        [@animateStaggerRemondis]="dataSource?.length"
        [@.disabled]="disableAnimations"
        multiTemplateDataRows
      >

        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef="let task">
            <button mat-icon-button (click)="editTask(task.uuid, task.taskType)" matTooltip="Edytuj" [attr.data-cy]="'editTask_'+task.name.split(' ').join('_')">
              <mat-icon>edit</mat-icon>
            </button>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="taskNumber">
          <mat-header-cell *matHeaderCellDef>{{'TASKS.TASK_NUMBER' | translate}}</mat-header-cell>
          <mat-cell *matCellDef="let task"> {{task.taskNumber}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="branch">
          <mat-header-cell *matHeaderCellDef>{{'TASKS.COMPANY_BRANCH_NAME' | translate}}</mat-header-cell>
          <mat-cell *matCellDef="let task"> {{task.companyBranch?.name}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="createdDate">
          <mat-header-cell *matHeaderCellDef mat-sort-header="createdDate">{{'TASKS.CREATED_DATE' | translate}}
          </mat-header-cell>
          <mat-cell *matCellDef="let task"> {{task.createdDate | date:'yyy-MM-dd HH:mm':'':'pl'}}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="startDate">
          <mat-header-cell *matHeaderCellDef mat-sort-header="startDate">{{'TASKS.TASK_DATE' | translate}}
          </mat-header-cell>
          <mat-cell *matCellDef="let task"> {{task.startDate | date:'yyy-MM-dd':'':'pl'}}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="taskName">
          <mat-header-cell *matHeaderCellDef mat-sort-header="taskName">{{'TASKS.NAME' | translate}}</mat-header-cell>
          <mat-cell *matCellDef="let task"> <span [matTooltip]="task.name" matTooltipPosition="above">
            {{task.name  || ''}}</span></mat-cell>
        </ng-container>


        <ng-container matColumnDef="forwarderExpense">
          <mat-header-cell *matHeaderCellDef mat-sort-header="forwarderExpense">{{'TASKS.EXPENSE' | translate}}</mat-header-cell>
          <mat-cell *matCellDef="let task"> {{task.forwarderExpense  || ''}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="forwarderInvoiceNumber">
          <mat-header-cell *matHeaderCellDef >{{'TASKS.INVOICE_NUMBER' | translate}}</mat-header-cell>
          <mat-cell *matCellDef="let task"> {{task.forwarderInvoiceNumber || '' }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="forwarderCompanyName">
          <mat-header-cell *matHeaderCellDef >{{'TASKS.FORWARDER_ORIGINAL_COMPANY_NAME' | translate}}</mat-header-cell>
          <mat-cell *matCellDef="let task"> {{task.originalCompanyName || '' }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="status">
          <mat-header-cell *matHeaderCellDef mat-sort-header="status">{{'TASKS.STATUS' | translate}}</mat-header-cell>
          <mat-cell *matCellDef="let task"> {{'TASKS.STATUSES.' + task.status.toUpperCase() | translate}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="forwarderRegistrationNumber">
          <mat-header-cell *matHeaderCellDef >{{'TASKS.REGISTRATION_NUMBER' | translate}}</mat-header-cell>
          <mat-cell *matCellDef="let task"> {{task.forwarderRegistrationNumberFront}} <br/> {{task.forwarderRegistrationNumberBack}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="forwarderDriver">
          <mat-header-cell *matHeaderCellDef>{{'TASKS.DRIVER' | translate}}</mat-header-cell>
          <mat-cell *matCellDef="let task"> {{task.forwarderDriverName}} <br/> {{task.forwarderDriverPhone}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="forwarderCarType">
          <mat-header-cell *matHeaderCellDef>{{'TASKS.CAR_TYPE' | translate}}</mat-header-cell>
          <mat-cell *matCellDef="let task"> {{task.forwarderCarType}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="comment">
          <mat-header-cell *matHeaderCellDef>{{'TASKS.COMMENT' | translate}}</mat-header-cell>
          <mat-cell *matCellDef="let task">
             <span *ngIf="!!task.comment" class="badge-type import"
                   [matTooltip]="task.comment"
                   matTooltipPosition="above">
              !
            </span></mat-cell>
        </ng-container>

        <ng-container matColumnDef="cargo">
          <mat-header-cell *matHeaderCellDef>{{'TASKS.CARGO' | translate}}</mat-header-cell>
          <mat-cell *matCellDef="let task">
            <div *ngIf="task.taskType !== taskTypes.Export">
              <ng-container *ngFor="let cargo of objectKeys(task.totalWeight); let i = index">
                <ng-container *ngIf="i <2">{{cargo}} {{task.totalWeight[cargo] + ' kg'}}<br/></ng-container>
                <ng-container *ngIf="i === 2"> ...</ng-container>
              </ng-container>
            </div>
            <div *ngIf="task.taskType === taskTypes.Export">
              <ng-container *ngFor="let fraction of task.totalWeight.name; let i = index">
                <ng-container *ngIf="i <2">{{fraction}}<br/></ng-container>
                <ng-container *ngIf="i === 2"> ...</ng-container>
              </ng-container>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="suggestedDate">
          <mat-header-cell *matHeaderCellDef mat-sort-header="suggestedDate">{{'TASKS.SUGGESTED_DATE' | translate}}
          </mat-header-cell>
          <mat-cell *matCellDef="let task"> {{task.taskData[0].order.suggestedDate | date:'yyy-MM-dd':'':'pl'}}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="taskType">
          <mat-header-cell *matHeaderCellDef mat-sort-header="taskType">{{'TASKS.TYPE' | translate}}</mat-header-cell>
          <mat-cell *matCellDef="let order">
             <span class="badge-type" [ngClass]="order.taskType"
                   [matTooltip]="'TASKS.TYPES.' + order.taskType.toUpperCase() | translate"
                   matTooltipPosition="above">
              {{'TASKS.TYPES.' + order.taskType.toUpperCase() + '.SHORT' | translate}}
            </span></mat-cell>
        </ng-container>

        <!--EXPANDED PANEL-->
        <ng-container matColumnDef="expandedDetail">
          <mat-cell class="task-details" *matCellDef="let task" [attr.colspan]="displayedColumns.length"
                    [@detailExpand]="task == expandedElement ? 'expanded' : 'collapsed'">
            <div class="task-element-detail">
              <div class="task-item-title">{{'TASKS.LIST.CARGO' | translate}}:</div>
              <div *ngIf="!task.totalWeight.name">
                <ul *ngFor="let cargo of objectKeys(task.totalWeight)">
                  <li>{{cargo}} - {{task.totalWeight[cargo]}} kg</li>
                </ul>
              </div>
              <div *ngIf="task.totalWeight.name">
                <ul *ngFor="let cargo of task.totalWeight.name">
                  <li>{{cargo}}</li>
                </ul>
                {{'TASKS.LIST.WEIGHT' | translate}}: {{task.totalWeight.totalWeight}} kg
              </div>
            </div>
            <div *ngIf="task.packages.length > 0" class="task-element-detail">
              <div class="task-item-title">{{'TASKS.LIST.PACKAGES' | translate}}:</div>
              <div >
                <ul *ngFor="let package of task.packages">
                  <li>{{package.name}} - {{package.value}} {{'TASKS.LIST.UNITS.'+package.unit.toUpperCase() |
                    translate}}
                  </li>
                </ul>
              </div>
            </div>
            <div class="task-element-detail" *ngIf="task.companies.length > 0">
              <div class="task-item-title">{{'TASKS.LIST.COMPANIES' | translate}}:</div>
              <ul *ngFor="let company of task.companies">
                <li>{{company.name}} - NIP: {{company.nip}}, {{company.address.city}}</li>
              </ul>
            </div>
            <div class="task-element-detail" *ngIf="task.companies.length > 0">
              <div class="task-item-title">{{'TASKS.LIST.ADDRESSES' | translate}}:</div>
              <div *ngIf="task.taskType !== taskTypes.Import">
                <ul *ngFor="let address of task.customerCompanyBranches">
                  <li>{{address?.name}}<br/>{{address?.street}}, {{address?.postalCode}} {{address?.city}}, {{address?.country}}</li>
                </ul>
              </div>
              <div *ngIf="task.taskType === taskTypes.Import">
                -
              </div>
            </div>
          </mat-cell>
          <mat-cell></mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row
          class="task-row"
          *matRowDef="let task; columns: displayedColumns;"
          [@animateRemondis]="{value:'*',params:{y:'100%'}}"
          [id]="task.uuid"
          [class.task-expanded-row]="expandedElement === task"
          (click)="expandedElement = expandedElement === task ? null : task">
          >
        </mat-row>
        <mat-row *matRowDef="let row; columns: ['expandedDetail']" class="task-detail-row"></mat-row>
      </mat-table>
      <h4 class="no-data" *ngIf="totalCount === 0">
        {{'SHARED.NO_DATA' | translate}}
      </h4>

      <div *ngIf="totalCount" class="paginator-container">
        <mat-paginator
          (page)="pageChange($event)"
          [pageSize]="requestParams.limit"
          [pageIndex]="(requestParams.page - 1)"
          [length]="totalCount"
          class="paginator-holder"
        ></mat-paginator>
      </div>
    </div>
  </div>
</div>

