import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { AuthService } from './auth.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./auth.service";
var FilteredListsService = /** @class */ (function () {
    function FilteredListsService(http, authService) {
        this.http = http;
        this.authService = authService;
    }
    FilteredListsService.prototype.getCompaniesList = function (params) {
        return this.http.get(environment.apiUrl + "/" + this.authService.getUserCompanyId() + "/customersCompanies", { params: params })
            .pipe(map(function (res) { return res; }));
    };
    FilteredListsService.prototype.getPackageTypesList = function (params) {
        return this.http.get(environment.apiUrl + "/" + this.authService.getUserCompanyId() + "/package", { params: params })
            .pipe(map(function (res) { return res; }));
    };
    FilteredListsService.prototype.getRecyclingItemsList = function (params) {
        return this.http.get(environment.apiUrl + "/" + this.authService.getUserCompanyId() + "/recycling", { params: params })
            .pipe(map(function (res) { return res; }));
    };
    FilteredListsService.prototype.getTransportList = function (params) {
        return this.http.get(environment.apiUrl + "/" + this.authService.getUserCompanyId() + "/transport", { params: params })
            .pipe(map(function (res) { return res; }));
    };
    FilteredListsService.prototype.getFractionsList = function (params) {
        return this.http.get(environment.apiUrl + "/" + this.authService.getUserCompanyId() + "/fraction", { params: params })
            .pipe(map(function (res) { return res; }));
    };
    FilteredListsService.prototype.getOrdersList = function (params) {
        return this.http.get(environment.apiUrl + "/" + this.authService.getUserCompanyId() + "/orders", { params: params })
            .pipe(map(function (res) { return res; }));
    };
    FilteredListsService.ngInjectableDef = i0.defineInjectable({ factory: function FilteredListsService_Factory() { return new FilteredListsService(i0.inject(i1.HttpClient), i0.inject(i2.AuthService)); }, token: FilteredListsService, providedIn: "root" });
    return FilteredListsService;
}());
export { FilteredListsService };
