/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./tasks-container.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "./tasks-container.component";
var styles_TasksContainerComponent = [i0.styles];
var RenderType_TasksContainerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TasksContainerComponent, data: {} });
export { RenderType_TasksContainerComponent as RenderType_TasksContainerComponent };
export function View_TasksContainerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(1, 212992, null, 0, i2.RouterOutlet, [i2.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_TasksContainerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-tasks-container", [], null, null, null, View_TasksContainerComponent_0, RenderType_TasksContainerComponent)), i1.ɵdid(1, 114688, null, 0, i3.TasksContainerComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TasksContainerComponentNgFactory = i1.ɵccf("app-tasks-container", i3.TasksContainerComponent, View_TasksContainerComponent_Host_0, {}, {}, []);
export { TasksContainerComponentNgFactory as TasksContainerComponentNgFactory };
