<div class="dialog-content-wrapper">
  <mat-toolbar matDialogTitle class="mat-accent m-0">
    <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
      <span class="title dialog-title">{{getTitle()}}</span>
      <button mat-icon-button (click)="userEditorDialogRef.close()" aria-label="Close dialog">
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar-row>

    <mat-toolbar-row *ngIf="mode !== 'add'" class="toolbar-bottom py-16" fxLayout="column"
                     fxLayoutAlign="center center">
      <avatar class="avatar" name="{{editedUser?.avatarName}}" [size]="72" background="#fff"
              [displayType]="'circle'"></avatar>
      <div class="contact-name mt-8">{{editedUser?.name}} {{editedUser?.surname}}</div>
    </mat-toolbar-row>
  </mat-toolbar>

  <div mat-dialog-content class="p-24 pb-0 m-0" fusePerfectScrollbar>

    <form [formGroup]="userForm">

      <div fxLayout="row" fxLayoutAlign="start start">
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>{{'USERS.EMAIL' | translate}}</mat-label>
          <mat-icon matSuffix class="secondary-text">email</mat-icon>
          <input name="email" formControlName="email" matInput type="email" required [attr.data-cy]="'userEmail'">
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutAlign="start start">
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>{{'USERS.FIRST_NAME' | translate}}</mat-label>
          <mat-icon matSuffix class="secondary-text">account_box</mat-icon>
          <input name="name" formControlName="name" matInput required [attr.data-cy]="'userName'">
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutAlign="start start">
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>{{'USERS.LAST_NAME' | translate}}</mat-label>
          <mat-icon matSuffix class="secondary-text">account_box</mat-icon>
          <input name="lastName" formControlName="surname" matInput required [attr.data-cy]="'userLastName'">
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutAlign="start start">
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>{{'USERS.TYPE' | translate}}</mat-label>
          <mat-select formControlName="userType" required [attr.data-cy]="'userSetType'">
            <mat-option [value]="userTypes.Logistician" [attr.data-cy]="'LOGISTICIAN'">{{'USERS.TYPES.LOGISTICIAN' | translate}}</mat-option>
            <mat-option [value]="userTypes.Manager" [attr.data-cy]="'MANAGER'">{{'USERS.TYPES.MANAGER' | translate}}</mat-option>
            <mat-option [value]="userTypes.Sales" [attr.data-cy]="'SALES'">{{'USERS.TYPES.SALES' | translate}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutAlign="start start">
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>{{'USERS.ROLE' | translate}}</mat-label>
          <mat-select formControlName="roleType" required [attr.data-cy]="'userRoleType'">
            <mat-option value="admin" [attr.data-cy]="'ADMIN'">{{'USERS.ROLES.ADMIN' | translate}}</mat-option>
            <mat-option value="user" [attr.data-cy]="'USER'">{{'USERS.ROLES.USER' | translate}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutAlign="start start">
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>{{'USERS.COMPANY_BRANCH' | translate}}</mat-label>
          <mat-select formControlName="companyBranchId" required [attr.data-cy]="'userCompanyBranch'">
            <mat-option *ngFor="let companyBranch of companyBranches" [value]="companyBranch.uuid" [attr.data-cy]="companyBranch.name.toUpperCase()">
              {{ companyBranch.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutAlign="start start" *ngIf="mode !== 'add'">
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>{{'USERS.ACTIVE' | translate}}</mat-label>
          <mat-select formControlName="active" required [attr.data-cy]="'userStatus'">
            <mat-option [value]="true" [attr.data-cy]="'ACTIVE'">{{'USERS.STATUSES.ACTIVE' | translate}}</mat-option>
            <mat-option [value]="false" [attr.data-cy]="'INACTIVE'">{{'USERS.STATUSES.INACTIVE' | translate}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

    </form>

    <div *ngIf="mode === 'edit' && !changePassword"
         fxLayoutAlign="center start">
      <button mat-button
              class="mr-8"
              color="warn"
              [attr.data-cy]="'openChangePassword'"
              (click)="toggleChangePassword()">
        {{'USERS.PASSWORD.CHANGE_PASSWORD' | translate}}
      </button>
    </div>
    <div *ngIf="mode === 'edit' && changePassword"
         fxLayoutAlign="end start">
      <button mat-icon-button
              color="warn"
              [attr.data-cy]="'closeChangePassword'"
              (click)="toggleChangePassword()">
        <mat-icon>close</mat-icon>
      </button>
    </div>

    <form *ngIf="changePassword" [formGroup]="passwordForm">

      <div fxLayout="row" fxLayoutAlign="start start">
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>{{'USERS.PASSWORD.PASSWORD' | translate}}</mat-label>
          <mat-icon matSuffix class="secondary-text">vpn_key</mat-icon>
          <input name="email" formControlName="password" type="password" matInput required [attr.data-cy]="'userNewPassword'">

          <mat-error
            *ngIf="passwordForm.get('password').touched && passwordForm.controls['password'].errors?.minlength">
            {{'USERS.PASSWORD.PASSWORDS_MIN_CHARACTERS' | translate}}
          </mat-error>
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutAlign="start start">
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>{{'USERS.PASSWORD.CONFIRM_PASSWORD' | translate}}</mat-label>
          <mat-icon matSuffix class="secondary-text">vpn_key</mat-icon>
          <input name="name" formControlName="confirmPassword" type="password" matInput required [attr.data-cy]="'userConfirmPassword'">

          <mat-error
            *ngIf="passwordForm.get('confirmPassword').touched && passwordForm.controls['confirmPassword'].errors?.MatchPassword">
            {{'USERS.PASSWORD.PASSWORDS_NOT_MATCH' | translate}}
          </mat-error>
        </mat-form-field>
      </div>

    </form>

  </div>

  <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="end center">

    <button *ngIf="mode === 'edit' && isAdmin"
            mat-button
            class="mr-8"
            [attr.data-cy]="'userDelete'"
            (click)="deleteUser()">
      {{'USERS.DELETE' | translate}}
    </button>

    <button *ngIf="mode === 'view' && isAdmin"
            mat-button
            class="mr-8"
            [attr.data-cy]="'userEdit'"
            (click)="enableEditMode()">
      {{'USERS.EDIT' | translate}}
    </button>

    <button *ngIf="(mode === 'edit' || mode === 'add') && isAdmin"
            mat-button
            class="save-button"
            [attr.data-cy]="'userSave'"
            (click)="submitUser()"
            [disabled]="userForm.invalid || (changePassword && passwordForm.invalid)">
      <span *ngIf="!isLoading"> {{'USERS.SAVE' | translate}}</span>
      <span *ngIf="isLoading" class="loading-spinner-wrapper">
        <mat-progress-spinner mode="indeterminate"
                              [strokeWidth]="2"
                              [diameter]="20">
        </mat-progress-spinner>
      </span>
    </button>

  </div>
</div>