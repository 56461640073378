<div class="navbar-top" [ngClass]="fuseConfig.layout.navbar.secondaryBackground">

  <div class="logo" fxHide.lt-lg (click)="toggleSidebarFolded()">
    <img class="logo-icon" [src]="getFolded() ? 'assets/images/logo-min_white.svg': 'assets/images/logo_white.svg'">
  </div>

  <!--TOGGLE MENU BUTTON-->
  <div class="buttons">
    <button mat-icon-button class="toggle-sidebar-folded"
            (click)="toggleSidebarFolded()" fxHide.lt-lg>
      <mat-icon class="secondary-text">arrow_back</mat-icon>
    </button>

    <button mat-icon-button class="toggle-sidebar-opened"
            (click)="toggleSidebarClose()" fxHide.gt-md>
      <mat-icon class="secondary-text">arrow_back</mat-icon>
    </button>
  </div>
</div>


<div class="navbar-scroll-container" [ngClass]="fuseConfig.layout.navbar.primaryBackground"
     fusePerfectScrollbar [fusePerfectScrollbarOptions]="{suppressScrollX: true}">

  <div class="usertype-wrapper">
    <h2 class="usertype">{{'NAVBAR.TITLE' | translate}}</h2>
  </div>

  <div class="user" fxLayout="column" [ngClass]="fuseConfig.layout.navbar.secondaryBackground">
    <div class="h3 username">{{user?.name}} {{user?.surname}}</div>
    <div class="h5 email hint-text mt-8">{{user?.email}}</div>
    <div class="avatar-container container-background" [ngClass]="fuseConfig.layout.navbar.primaryBackground">
      <button mat-button [matMenuTriggerFor]="userMenu"
              >
        <div fxLayout="row" fxLayoutAlign="center center">
          <avatar name="{{user?.avatarName}}" size="40" background="#E53934" [displayType]="'circle'"></avatar>
          <mat-icon class="s-16" fxHide.xs>keyboard_arrow_down</mat-icon>
        </div>
      </button>

      <mat-menu #userMenu="matMenu" [overlapTrigger]="false">

        <button mat-menu-item (click)="selectCompany()">
          <mat-icon>group</mat-icon>
          <span>{{'SELECT_COMPANY' | translate}}</span>
        </button>

        <button mat-menu-item (click)="logout()">
          <mat-icon>exit_to_app</mat-icon>
          <span>{{'TOOLBAR.LOGOUT' | translate}}</span>
        </button>

      </mat-menu>
    </div>
  </div>


  <div class="navbar-content">
    <fuse-navigation class="material2" layout="vertical"></fuse-navigation>
  </div>
  <div fxLayout="row" fxLayoutAlign="end center" fxShow.gt-xs>
    <a class="maio-footer" href="https://maiosoftwarehouse.com" target="_blank">
      <span *ngIf="!closeMenu">Powered by</span>
      <img class="maio-logo" src="assets/images/logo-maio.svg"/>
    </a>
  </div>
</div>
