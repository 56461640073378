<div id="change-password" fxLayout="column">

  <div id="change-password-form-wrapper" fxLayout="column" fxLayoutAlign="center center">

    <div id="change-password-form">

      <div class="logo">
        <img src="assets/images/logo.svg">
      </div>

      <div class="title"> {{'SELECT_COMPANY' | translate}}</div>

      <form name="changePasswordForm" [formGroup]="selectCompanyForm" novalidate (ngSubmit)="submit()">

        <mat-form-field appearance="outline">
          <mat-label>{{'COMPANY' | translate}}</mat-label>
          <mat-select formControlName="companyId" required>
            <mat-option *ngFor="let company of companies" [value]="company.uuid">
              {{ company.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <button mat-raised-button
                type="submit"
                color="primary"
                class="submit-button"
                aria-label="LOG IN"
                [disabled]="selectCompanyForm.invalid || isLoading">
          <span *ngIf="!isLoading">
            {{'SUBMIT' | translate}}
          </span>
          <span *ngIf="isLoading" class="loading-spinner-wrapper">
              <mat-progress-spinner
                mode="indeterminate"
                [strokeWidth]="2"
                [diameter]="20">
              </mat-progress-spinner>
            </span>
        </button>

      </form>

    </div>

  </div>

</div>