import { FuseNavigationService } from '../../@fuse/components/navigation/navigation.service';
import * as i0 from "@angular/core";
import * as i1 from "../../@fuse/components/navigation/navigation.service";
var NavigationService = /** @class */ (function () {
    function NavigationService(_fuseNavigationService) {
        this._fuseNavigationService = _fuseNavigationService;
    }
    NavigationService.prototype.navigation = function (userType, isUpdate) {
        var navigation = [
            {
                id: 'applications',
                title: '',
                type: 'group',
                children: [
                    {
                        id: 'tasks',
                        title: 'Zadania',
                        translate: 'NAV.MAIN.TASKS',
                        type: 'item',
                        icon: 'check_box',
                        url: '/tasks'
                    },
                ]
            }
        ];
        if (isUpdate) {
            this._fuseNavigationService.unregister('main');
            this._fuseNavigationService.register('main', navigation);
            this._fuseNavigationService.setCurrentNavigation('main');
        }
        return navigation;
    };
    NavigationService.ngInjectableDef = i0.defineInjectable({ factory: function NavigationService_Factory() { return new NavigationService(i0.inject(i1.FuseNavigationService)); }, token: NavigationService, providedIn: "root" });
    return NavigationService;
}());
export { NavigationService };
