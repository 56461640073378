import { EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { Company } from '../shared/models/companies.model';
import { AuthService } from './auth.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./auth.service";
var CompaniesService = /** @class */ (function () {
    function CompaniesService(http, authService) {
        this.http = http;
        this.authService = authService;
        this.onEditEvent = new EventEmitter();
    }
    CompaniesService.prototype.getCompany = function (companyUuid) {
        return this.http.get(environment.apiUrl + "/" + this.authService.getUserCompanyId() + "/customerCompany/" + companyUuid).pipe(map(function (res) { return res.customerCompany; }));
    };
    CompaniesService.ngInjectableDef = i0.defineInjectable({ factory: function CompaniesService_Factory() { return new CompaniesService(i0.inject(i1.HttpClient), i0.inject(i2.AuthService)); }, token: CompaniesService, providedIn: "root" });
    return CompaniesService;
}());
export { CompaniesService };
