import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import {Company, CompaniesListRespone, Gus} from '../shared/models/companies.model';
import { RequestParams } from '../shared/models/list.model';
import { AuthService } from './auth.service';
import { UsersListResponse } from '../shared/models/users.model';

@Injectable({
  providedIn: 'root'
})
export class CompaniesService {
  onEditEvent: EventEmitter<any> = new EventEmitter();

  constructor(private http: HttpClient, private authService: AuthService) {
  }


  getCompany(companyUuid: Company['uuid']): Observable<Company> {
    return this.http.get(`${environment.apiUrl}/${this.authService.getUserCompanyId()}/customerCompany/${companyUuid}`).pipe(
      map((res: any) => res.customerCompany)
    );
  }
}