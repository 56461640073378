export const locale = {
  lang: 'pl',
  data: {
    'NAVBAR': {
      'TITLE': 'Transport'
    },
    'SELECT_COMPANY': 'Wybierz firmę',
    'COMPANY': 'Firma',
    'SUBMIT': 'Zatwierdź',
    'ORDERS': {
      'TITLE_LIST': 'Zlecenia',
      'TITLE_ADD': 'Dodaj zlecenie',
      'TITLE_EDIT': 'Edytuj zlecenie',
      'TITLE_VIEW': 'Szczegóły zlecenia',
      'TITLE_PICK': 'Wybierz typ zlecenia',
      'CLEAR_DATE': 'Usuń datę',
      'ORDER': 'Zlecenie',
      'DATE': 'Data dodania',
      'SUGGESTED_DATE': 'Zaplanowana data odbioru',
      'NUMBER': 'Numer',
      'REFERENCE_NUMBER': 'Numer referencyjny ZO',
      'UNALLOCATED': 'Nieprzypisane',
      'CITY_RECEIVING': 'Miasto',
      'ORDER_NUMBER': 'Numer zlecenia',
      'NO_NUMBER': 'Zlecenie nie posiada numeru',
      'TYPE': 'Typ',
      'TYPES': {
        'IMPORT': 'Przywóz - dostawa transportem klienta',
        'EXPORT': 'Wywóz',
        'RECEIVING': 'Odbiór transportem REMONDIS',
        'IMPORT.SHORT': 'P',
        'EXPORT.SHORT': 'W',
        'RECEIVING.SHORT': 'O'
      },
      'OWNER': 'Właściciel',
      'CUSTOMER_COMPANY': 'Firma',
      'COMPANY_BRANCH': 'Oddział REMONDIS',
      'STATUS': 'Status',
      'STATUSES': {
        'NEW': 'Nowe',
        'READ': 'Odczytane',
        'PLANNED': 'Umówione',
        'CANCELLED': 'Anulowane',
        'CANCELLEDBYCLIENTREQUEST': 'Anulowane przez klienta',
        'COMPLETED': 'Zrealizowane'
      },
      'CLOSE_CHOSEN_ORDERS': 'Zamknij wybrane zlecenia',
      'GENERATE_TASK_FROM_CHOSEN_ORDERS': 'Wygeneruj zadanie dla wybranych zleceń',
      'TASK': 'Zadanie',
      'SEARCH_PLACEHOLDER': 'Szukaj zlecenia',
      'FRACTION': 'Frakcja',
      'TAG': 'Tag',
      'TRANSPORT': 'Środek transportu',
      'RECYCLING': 'Sprzęt do utylizacji',
      'PACKAGES': 'Sposoby pakowania',
      'PACKAGE': 'Sposób pakowania',
      'PACKAGE_NAME': 'Nazwa opakowania',
      'ACCEPTED': 'Zaakceptowane',
      'ACCEPTED_BY_SALES': 'Zaakceptowane przez dział handlowy',
      'NAME': 'Nazwa',
      'OTHER': 'inne',
      'QUANTITY': 'Ilość',
      'QUANTITY_KG': 'Masa w kg',
      'UNIT': 'Jednostka',
      'IS_READY': 'Towar gotowy',
      'STREET': 'Ulica',
      'POSTAL_CODE': 'Kod pocztowy',
      'CITY': 'Miasto',
      'COUNTRY': 'Kraj',
      'COMMENT': 'Uwagi',
      'INTERNAL_COMMENT': 'Uwagi wewnętrzne (dla kierowcy)',
      'FILES': 'Pliki',
      'ADD_FILE': 'Dodaj lub upuść zdjęcie',
      'ADDITIONAL_INFO': 'Dodatkowe informacje',
      'SUGGESTED_DATE_EXPORT': 'Sugerowana data wywozu',
      'SUGGESTED_DATE_IMPORT': 'Sugerowana data przywozu',
      'SUGGESTED_DATE_RECEIVING': 'Sugerowana data odbioru',
      'RECEIVING_TIME_FROM': 'Od godziny',
      'RECEIVING_TIME_TO': 'Do godziny',
      'CREATED_DATE': 'Data i godzina utworzenia',
      'UPDATED_DATE': 'Data i godzina edycji',
      'DESTINATION': 'Miejsce docelowe',
      'CUSTOMER_COMPANY_BRANCH': {
        'NAME': 'Nazwa miejsca odbioru',
        'OTHER': 'Inny',
        'STREET': 'Ulica i numer',
        'POSTAL_CODE': 'Kod pocztowy',
        'CITY': 'Miasto',
        'COUNTRY': 'Kraj',
        'CONTACT': 'Osoba kontaktowa',
        'PHONE': 'Numer telefonu',
        'EMAIL': 'Email',
        'NOTE': 'Uwagi',
        'CLIENT_COMMENT': 'Uwagi od klienta',
      },
      'ADDRESS_OPTIONS': {
        'COMPANY_ADDRESS': 'Adres firmy',
        'DIFFERENT': 'Inny',
      },
      'RECEIVING_PLACE': 'Miejsce odbioru',
      'PICKUP_HOURS': 'Możliwe godziny odbioru',
      'REG_NUMBER_FRONT': 'Numer rejestracyjny przód',
      'REG_NUMBER_BACK': 'Numer rejestracyjny tył',
      'BDO': 'BDO',
      'BDO_NUMBER': 'Numer BDO',
      'NO_BDO_CHECKBOX': 'Odpady zwolnione z prowadzenia ewidencji (niewymagane e-KPO)',
      'BDO_PEOPLE_COLLECTION' : 'Zbiórka elektrośmieci',
      'RECYCLING_ITEMS': 'Sprzęty do utylizacji',
      'RECYCLING_ITEM': 'Sprzęt do utylizacji',
      'PACKAGE_TYPES': 'Sposoby pakowania',
      'PACKAGE_TYPE': 'Sposób pakowania',
      'ADD_RECYCLING_ITEM': 'Dodaj sprzęt do utylizacji',
      'ADD_PACKAGE_TYPE': 'Dodaj sposób pakowania',
      'SEARCH': 'Szukaj',
      'TOTAL_WEIGHT': 'Szacowana waga:',

      'GENERATE_TASK': 'Wygeneruj zadanie',
      'VIEW_TASK': 'Zobacz zadanie',
      'CANCEL_ORDER': 'Anuluj zlecenie',
      'PLAN_ORDER': 'Umów zlecenie',
      'SEND_ORDER_LINK': 'Wyślij link do utworzenia zlecenia',
      'SEND_ORDER_LINK_SHORT': 'W',
      'SHOW_HISTORY': 'Historia zlecenia',
      'DELETE': 'Usuń',
      'CANCEL': 'Anuluj',
      'EDIT': 'Edytuj',
      'CLONE': 'Klonuj',
      'SAVE': 'Zapisz',

      'MESSAGES': {
        'CREATE_SUCCESS': 'Dodano zlecenie.',
        'CREATE_ERROR': 'Nie udało się dodać zlecenia.',
        'CREATE_SUCCESS_FILES_ERROR': 'Dodano zlecenie. Nie udało się dodać niektórych plików.',
        'UPDATE_SUCCESS': 'Zaktualizowano zlecenie.',
        'GENERATE_ERROR': 'Nie udało się wygenerować zadania. Brak wybranego oddziału i/lub akceptacji przez dział handlowy',
        'UPDATE_ERROR': 'Nie udało się zaktualizować zlecenia.',
        'CANCEL_QUESTION': 'Czy na pewno chcesz anulować to zlecenie?',
        'CANCEL_ORDER_TITLE': 'Anuluj zlecenie',
        'CANCEL_SUCCESS': 'Anulowano zlecenie.',
        'CANCEL_ERROR': 'Nie udało się anulować zlecenia.',
        'PLAN_QUESTION': 'Czy na pewno chcesz umówić to zlecenie?',
        'PLAN_ORDER_TITLE': 'Umów zlecenie',
        'PLAN_SUCCESS': 'Zmieniono status zlecenia na umówione.',
        'PLAN_ERROR': 'Nie udało się zmienić statusu zlecenia na umówione.',
        'SEND_LINK_TITLE': 'Podaj email, na który zostanie wysłany unikalny link do utworzenia zlecenia.',
        'SEND_LINK_SUCCESS': 'Link do utworzenia zlecenia został wysłany.',
        'SEND_LINK_ERROR': 'Nie udało się wysłać linku do zlecenia.',
        'DELETE_QUESTION': 'Czy na pewno chcesz usunąć to zlecenie?',
        'DELETE_ORDER_TITLE': 'Usuń zlecenie',
        'DELETE_SUCCESS': 'Usunięto zlecenie.',
        'DELETE_ERROR': 'Nie udało się usunąć zlecenia.',
        'DELETE_FILE_QUESTION': 'Czy na pewno chcesz usunąć ten plik?',
        'ADD_FILE_SUCCESS': 'Dodano plik.',
        'ADD_FILE_ERROR': 'Nie udało się dodać pliku.',
        'DELETE_FILE_TITLE': 'Usuń plik',
        'DELETE_FILE_SUCCESS': 'Usunięto plik.',
        'DELETE_FILE_ERROR': 'Nie udało się usunąć pliku.',
        'ERROR': 'Wystąpił błąd. Spróbuj ponownie.'
      }
    },
    'TASKS': {
      'TITLE_LIST': 'Zadania',
      'TITLE_ADD': 'Dodaj zadanie',
      'TITLE_EDIT': 'Edytuj zadanie',
      'TITLE_VIEW': 'Szczegóły zadania',
      'TITLE_PICK': 'Wybierz typ zadania',
      'DATE': 'Data zadania',
      'TYPE': 'Typ',
      'TYPES': {
        'IMPORT': 'Przywóz',
        'EXPORT': 'Wywóz',
        'RECEIVING': 'Odbiór',
        'IMPORT.SHORT': 'P',
        'EXPORT.SHORT': 'W',
        'RECEIVING.SHORT': 'O'
      },
      'AUTHOR': 'Autor',
      'CUSTOMER_COMPANY': 'Firma',
      'COMPANY_BRANCH': 'Oddział REMONDIS',
      'COMPANY_BRANCH_NAME': 'Oddział',
      'STATUS': 'Status',
      'STATUSES': {
        'NEW': 'Nowe',
        'READ': 'Odczytane',
        'PLANNED': 'Umówione',
        'CANCELLED': 'Anulowane',
        'REALIZED': 'Zrealizowane',
        'CANCELLEDBYCLIENTREQUEST': 'Anulowane przez klienta',
        'INVOICED' : 'Zafakturowane',
        'SEND_TO_FORWARDER': 'Wysłano do transportującego',
        'NOTIFIED': 'Zaawizowane',
        'WAITING_FOR_ACCEPT': 'Oczekujące na akceptację',
        'SEND_TO_TRANSPORT': 'Wysłane do transportu',
        'CLOSED': 'Zamknięte',
        'CANCELLED_BY_USER': 'Anulowane przez użytkownika',
        'DONE': 'Zrealizowane'
      },
      'TASK': 'Zadanie',
      'SEARCH_PLACEHOLDER': 'Szukaj zadania',
      'FRACTION': 'Frakcja',
      'TAG': 'Tag',
      'TRANSPORT': 'Środek transportu',
      'RECYCLING': 'Sprzęt do utylizacji',
      'CARGO': 'Towar',
      'COMPANY': 'Firma',
      'PACKAGES': 'Sposoby pakowania',
      'PACKAGE': 'Sposób pakowania',
      'ACCEPTED': 'Zaakceptowane',
      'ACCEPTED_BY_SALES': 'Zaakceptowane przez dział handlowy',
      'NAME': 'Tytuł',
      'OTHER': 'inne',
      'QUANTITY': 'Ilość',
      'QUANTITY_KG': 'Masa w kg',
      'UNIT': 'Jednostka',
      'IS_READY': 'Towar gotowy',
      'STREET': 'Ulica',
      'POSTAL_CODE': 'Kod pocztowy',
      'CITY': 'Miasto',
      'COUNTRY': 'Kraj',
      'NUMBER': 'Numer zadania',
      'NO_NUMBER': 'Zadanie nie posiada numeru',
      'COMMENT': 'Uwagi',
      'CLIENT_COMMENT': 'Uwagi od klienta',
      'REFERENCE_NUMBER': 'Numer referencyjny ZT',
      'ADDITIONAL_INFO': 'Dodatkowe informacje',
      'TASK_DATE': 'Data zadania',
      'START_DATE': 'Data rozpoczęcia zadania',
      'END_DATE': 'Data zakończenia zadania',
      'START_TIME': 'Godzina rozpoczęcia zadania',
      'END_TIME': 'Godzina zakończenia zadania',
      'UPDATED_DATE': 'Data i godzina edycji',
      'DESTINATION': 'Miejsce docelowe',
      'INVOICE_NUMBER': 'Nr faktury',
      'FORWARDER_COMPANY_NAME': 'Przewoźnik',
      'FORWARDER_ORIGINAL_COMPANY_NAME': 'Przewoźnik (fakturujący)',
      'TASK_NUMBER': 'Numer',
      'CREATED_DATE': 'Data utworzenia',
      'SUGGESTED_DATE': 'Data realizacji',
      'REGISTRATION_NUMBER': 'Nr rej.',
      'DRIVER': 'Kierowca',
      'CAR_TYPE': 'Typ samochodu',
      'EXPENSE': 'Koszt [zł]',
      'PICKUP_PLACE': 'Miejsce odbioru',
      'ADDRESS_OPTIONS': {
        'COMPANY_ADDRESS': 'Adres firmy',
        'DIFFERENT': 'Inny',
      },
      'RECEIVING_PLACE': 'Miejsce wywozu',
      'PICKUP_HOURS': 'Możliwe godziny odbioru',
      'REG_NUMBER_FRONT': 'Numer rejestracyjny przód',
      'REG_NUMBER_BACK': 'Numer rejestracyjny tył',

      'GENERAL_INFO': 'Informacje ogólne',
      'ORDER': 'Zlecenie',
      'ORDERS': 'Zlecenia',
      'ADD_ORDER': 'Dodaj zlecenie',

      'SHOW_HISTORY': 'Historia zadania',
      'DELETE': 'Usuń',
      'CANCEL_SKIP': 'Wróć',
      'UPDATE': 'Zmień',
      'REVOKE': 'Odwołaj',
      'CANCEL_TASK': 'Anuluj zadanie',
      'EDIT': 'Edytuj',
      'CLONE': 'Klonuj',
      'SAVE': 'Zapisz',
      'ADD_INVOICE': 'Dodaj nr faktury',
      'LIST': {
        'CARGO': 'Towar',
        'PACKAGES': 'Opakowania',
        'COMPANIES': 'Firmy',
        'ADDRESSES': 'Adresy',
        'WEIGHT': 'Masa',
        'UNITS': {
          'KG': 'kg',
          'PIECES': 'szt.',
          'M3' : 'm3'
        }
      },
      'RECURRING': {
        'CHECKBOX': 'Zadanie powtarzalne',
        'INTERVAL': 'Co ile tygodni',
        'RANGE_DATE_END': 'Do kiedy',
        'MONDAY': 'Pn',
        'TUESDAY': 'Wt',
        'WEDNESDAY': 'Śr',
        'THURSDAY': 'Czw',
        'FRIDAY': 'Pt',
        'SATURDAY': 'Sb',
        'SUNDAY': 'Ndz',
      },
      'DOWNLOAD_PDF': {
        'BUTTON': 'Pobierz zadanie',
        'TOOLTIP': 'Wygeneruj dokument pdf z informacjami o zadaniu',
        'TABLE': {
          'COMPANY': 'Nazwa firmy',
          'ADDRESS': 'Miejsce',
          'HOURS': 'Godziny otwarcia',
          'CONTACT': 'Osoba kontaktowa',
          'PACK': 'Sposób pakowania',
          'CARGO': 'Towar',
          'WEIGHT': 'Waga',
          'COMMENTS': 'Uwagi',
          'BDO': 'BDO',
          'PEOPLE_COLLECTION': 'Zbiórka',
          'BDO_FREE': 'Zwolniony',
          'FRACTIONS': 'Frakcje'
        }
      },
      'PDFFILTER': {
        'HEADER': 'Pobierz zadania',
        'PLACEHOLDER': 'Wybierz dzień',
        'FILENAME': 'Zadania',
        'BUTTON': 'Generuj pdf',
        'TOOLTIP': 'Wygeneruj dokument pdf wybierając datę',
        'NONE': 'brak',
        'UNITS': {
          'PIECES': 'szt.',
          'KG': 'kg',
          'M3': 'm3'
        },
        'TABLE': {
          'HOUR': 'Godzina',
          'TYPE': 'Typ',
          'TITLE': 'Tytuł',
          'COMPANY': 'Firma',
          'CARGO': 'Towar',
          'VEHICLE': 'Pojazd',
          'COMMENTS': 'Uwagi'
        }
      },
      'FORWARDER' : {
        'NAME': 'Transport',
        'COMPANY_SELECT': 'Wybierz firmę',
        'COMPANY_NAME' : 'Nazwa firmy transportującej',
        'ORIGINAL_COMPANY_NAME' : 'Firma',
        'CAR_SELECT': 'Wybierz samochód',
        'DRIVER_NAME': 'Kierowca',
        'DRIVER_PHONE': 'Nr tel. kierowcy',
        'REGISTRATION_FRONT': 'Nr rej. przód',
        'REGISTRATION_BACK': 'Nr rej. tył',
        'BDO': 'BDO tranportującego',
        'CAR_NAME': 'Nazwa samochodu',
        'CAR_TYPE': 'Typ samochodu',
        'EXPENSE': 'Koszt',
        'INVOICE_NUMBER': 'Nr faktury',
        'KILOMETERS': 'Kilometry',
        'ROUTE_DESCRIPTION': 'Komentarz (np. trasa google)'
      },
      'MESSAGES': {
        'CREATE_SUCCESS': 'Dodano zadanie.',
        'CREATE_ERROR': 'Nie udało się dodać zadania.',
        'UPDATE_TASK_TITLE': 'Edytuj zadanie',
        'UPDATE_QUESTION': 'Czy na pewno chcesz edytować to zadanie?',
        'UPDATE_SUCCESS': 'Zaktualizowano zadanie.',
        'UPDATE_ERROR': 'Nie udało się zaktualizować zadania.',
        'DELETE_QUESTION': 'Czy na pewno chcesz usunąć to zadanie?',
        'DELETE_TASK_TITLE': 'Usuń zadanie',
        'DELETE_SUCCESS': 'Usunięto zadanie.',
        'DELETE_ERROR': 'Nie udało się usunąć zadania.',
        'CANCEL_QUESTION': 'Czy na pewno chcesz anulować to zadanie?',
        'CANCEL_TASK_TITLE': 'Anuluj zadanie',
        'CANCEL_SUCCESS': 'Anulowano zadanie.',
        'CANCEL_ERROR': 'Nie udało się anulować zadania.',
        'END_DATE_ERROR': 'Data zakończenia nie może być wcześniejsza niż data rozpoczęcia zadania.',
        'ERROR': 'Wystąpił błąd. Spróbuj ponownie.'
      },
      'STEPPER': {
        'PREV': 'Wstecz',
        'NEXT': 'Dalej'
      }
    },
    'FRACTIONS': {
      'TITLE_LIST': 'Frakcje',
      'TITLE_ADD': 'Dodaj frakcję',
      'TITLE_EDIT': 'Edytuj frakcję',
      'TITLE_VIEW': 'Szczegóły frakcji',
      'NAME': 'Nazwa',
      'DESC': 'Opis',
      'CODE': 'Kod',
      'SEARCH_PLACEHOLDER': 'Szukaj frakcji',
      'STATUS': 'Status',
      'STATUSES': {
        'ACTIVE': 'Aktywny',
        'INACTIVE': 'Nieaktywny',
      },
      'DELETE': 'Usuń',
      'CANCEL': 'Anuluj',
      'EDIT': 'Edytuj',
      'SAVE': 'Zapisz',

      'MESSAGES': {
        'CREATE_SUCCESS': 'Dodano frakcję.',
        'CREATE_ERROR': 'Nie udało się dodać frakcji.',
        'UPDATE_SUCCESS': 'Zaktualizowano frakcję.',
        'UPDATE_ERROR': 'Nie udało się zaktualizować frakcji.',
        'DELETE_QUESTION': 'Czy na pewno chcesz usunąć tę frakcję?',
        'DELETE_FRACTION_TITLE': 'Usuń frakcję',
        'DELETE_SUCCESS': 'Usunięto frakcję.',
        'DELETE_ERROR': 'Nie udało się usunąć frakcji.',
        'ERROR': 'Wystąpił błąd. Spróbuj ponownie.'
      }
    },
    'TAGS': {
      'TITLE_LIST': 'Tagi',
      'TITLE_ADD': 'Dodaj tag',
      'TITLE_EDIT': 'Edytuj tag',
      'TITLE_VIEW': 'Szczegóły tagu',
      'NAME': 'Nazwa',
      'SEARCH_PLACEHOLDER': 'Szukaj tagu',
      'INPUT_NAME': 'Tagi',
      'DELETE': 'Usuń',
      'CANCEL': 'Anuluj',
      'EDIT': 'Edytuj',
      'SAVE': 'Zapisz',
      'STATUS': 'Status',
      'MESSAGES': {
        'CREATE_SUCCESS': 'Dodano tag.',
        'CREATE_ERROR': 'Nie udało się dodać tagu.',
        'UPDATE_SUCCESS': 'Zaktualizowano tag.',
        'UPDATE_ERROR': 'Nie udało się zaktualizować tagu.',
        'DELETE_QUESTION': 'Czy na pewno chcesz usunąć ten tag?',
        'DELETE_TAG_TITLE': 'Usuń tag',
        'DELETE_SUCCESS': 'Usunięto tag.',
        'DELETE_ERROR': 'Nie udało się usunąć tagu.',
        'ERROR': 'Wystąpił błąd. Spróbuj ponownie.'
      },
      'STATUSES': {
        'ACTIVE': 'Aktywny',
        'INACTIVE': 'Nieaktywny'
      }
    },
    'TRANSPORT': {
      'TITLE_LIST': 'Środki transportu',
      'TITLE_ADD': 'Dodaj środek transportu',
      'TITLE_EDIT': 'Edytuj środek transportu',
      'TITLE_VIEW': 'Szczegóły środka transportu',
      'NAME': 'Nazwa',
      'DESC': 'Opis',
      'CAPACITY': 'Ładowność',
      'VOLUME': 'Objętość',
      'STATUS': 'Status',
      'STATUSES': {
        'ACTIVE': 'Aktywny',
        'INACTIVE': 'Nieaktywny',
      },
      'SEARCH_PLACEHOLDER': 'Szukaj środka transportu',
      'DELETE': 'Usuń',
      'CANCEL': 'Anuluj',
      'EDIT': 'Edytuj',
      'SAVE': 'Zapisz',

      'CATEGORY': 'Kategoria',
      'CATEGORIES': {
        '1': 'TIR',
        '2': 'Solo',
        '3': 'Kontener',
        '4': 'Dostawczy',
        '5': 'Inne',
      },

      'MESSAGES': {
        'CREATE_SUCCESS': 'Dodano środek transportu.',
        'CREATE_ERROR': 'Nie udało się dodać środka transportu.',
        'UPDATE_SUCCESS': 'Zaktualizowano środek transportu.',
        'UPDATE_ERROR': 'Nie udało się zaktualizować środka transportu.',
        'DELETE_QUESTION': 'Czy na pewno chcesz usunąć ten środek transportu?',
        'DELETE_TRANSPORT_TITLE': 'Usuń środek transportu',
        'DELETE_SUCCESS': 'Usunięto środek transportu.',
        'DELETE_ERROR': 'Nie udało się usunąć środka transportu.',
        'ERROR': 'Wystąpił błąd. Spróbuj ponownie.'
      }
    },
    'USERS': {
      'TITLE_LIST': 'Użytkownicy',
      'TITLE_ADD': 'Dodaj użytkownika',
      'TITLE_EDIT': 'Edytuj użytkownika',
      'TITLE_VIEW': 'Szczegóły użytkownika',
      'FIRST_NAME': 'Imię',
      'LAST_NAME': 'Nazwisko',
      'EMAIL': 'Email',
      'ROLE': 'Rola',
      'ROLES': {
        'ADMIN': 'Administrator',
        'USER': 'Użytkownik',
      },
      'TYPE': 'Typ konta',
      'TYPES': {
        'LOGISTICIAN': 'Logistyk',
        'MANAGER': 'Kierownik',
        'SALES': 'Handlowiec',
        'SUPERADMIN': 'Admin'
      },
      'COMPANY_BRANCH': 'Oddział',
      'ACTIVE': 'Status',
      'STATUSES': {
        'ACTIVE': 'Aktywny',
        'INACTIVE': 'Nieaktywny',
      },
      'PASSWORD': {
        'CHANGE_PASSWORD': 'Zmień hasło',
        'PASSWORD': 'Hasło',
        'CONFIRM_PASSWORD': 'Powtórz hasło',
        'RESET_PASSWORD_SUCCESS': 'Hasło zostało zmienione.',
        'RESET_PASSWORD_ERROR': 'Nie udało się zmienić hasła.',
        'PASSWORDS_MIN_CHARACTERS': 'Hasło musi się składać z minimum 5 znaków.',
        'PASSWORDS_NOT_MATCH': 'Te hasła nie pasują do siebie.',
      },
      'MESSAGES': {
        'CREATE_SUCCESS': 'Dodano użytkownika.',
        'CREATE_ERROR': 'Nie udało się dodać użytkownika.',
        'UPDATE_SUCCESS': 'Zaktualizowano użytkownika.',
        'UPDATE_ERROR': 'Nie udało się zaktualizować użytkownika.',
        'DELETE_QUESTION': 'Czy na pewno chcesz usunąć tego użytkownika?',
        'DELETE_USER_TITLE': 'Usuń użytkownika',
        'DELETE_SUCCESS': 'Usunięto użytkownika.',
        'DELETE_ERROR': 'Nie udało się usunąć użytkownika.',
      },
      'SEARCH_PLACEHOLDER': 'Szukaj użytkownika',
      'SAVE': 'Zapisz',
      'CANCEL': 'Anuluj',
      'DELETE': 'Usuń',
      'EDIT': 'Edytuj',
      'ERROR': 'Wystąpił błąd. Spróbuj ponownie.'
    },
    'CUSTOMER_COMPANIES': {
      'TITLE_LIST': 'Firmy',
      'TITLE_ADD': 'Dodaj firmę',
      'TITLE_EDIT': 'Edytuj firmę',
      'TITLE_VIEW': 'Szczegóły firmy',
      'COMPANY_DATA': 'Dane firmy',
      'COMPANY_ACCOUNTS': 'Konta',
      'COMPANY_BRANCHES': 'Oddziały',
      'COMPANY_CARS': 'Samochody',
      'BUSINESS_TERMS': 'Warunki handlowe',
      'NAME': 'Nazwa',
      'EMAIL': 'Email',
      'STREET': 'Ulica',
      'POSTAL_CODE': 'Kod pocztowy',
      'CITY': 'Miasto',
      'COUNTRY': 'Kraj',
      'NIP': 'NIP',
      'GET_DATA': 'Pobierz dane',
      'REGON': 'REGON',
      'BDO': 'BDO',
      'CONTACT': 'Osoba kontaktowa',
      'KRS': 'KRS',
      'PHONE': 'Numer telefonu',
      'OWNER': 'Opiekun',
      'TRUSTED': 'Zaufany',
      'UNTRUSTED': 'Niezaufany',
      'CREATED_DATE': 'Data utworzenia',
      'UPDATED_DATE': 'Data edycji',
      'DIFFERENT_INVOICE_ADDRESS': 'Inny adres do faktury',
      'INVOICE_ADDRESS': 'Dane do faktury',
      'ADDITIONAL_DATA': 'Dodatkowe informacje',

      'SEARCH_PLACEHOLDER': 'Szukaj firmy',
      'CLEAN': 'Wyczyść',
      'DELETE': 'Usuń',
      'CANCEL': 'Anuluj',
      'EDIT': 'Edytuj',
      'SAVE': 'Zapisz',

      'COMPANY_TYPE': 'Typ firmy',
      'COMPANY_TYPES': {
        'SUPPLIER': 'Dostawca',
        'RECIPIENT': 'Odbiorca',
        'FORWARDER': 'Przewoźnik',
      },
      'COMPANY_TYPES_SHORT': {
        'SUPPLIER': 'D',
        'RECIPIENT': 'O',
        'FORWARDER': 'P',
      },

      'MESSAGES': {
        'CREATE_SUCCESS': 'Dodano firmę.',
        'CREATE_ERROR': 'Nie udało się dodać firmy.',
        'CREATE_NIP_ERROR': 'Nie udało się dodać firmy. Taka firma już istnieje.',
        'INVALID_NIP': 'Nie udało się pobrać danych. Podany NIP jest nieprawidłowy',
        'VALID_NIP': 'Pobrano dane z GUS',
        'UPDATE_SUCCESS': 'Zaktualizowano firmę.',
        'UPDATE_ERROR': 'Nie udało się zaktualizować firmy.',
        'UPDATE_NIP_ERROR': 'Nie udało się zaktualizować firmy. Taka firma już istnieje.',
        'DELETE_QUESTION': 'Czy na pewno chcesz usunąć tę firmę?',
        'DELETE_COMPANY_TITLE': 'Usuń firmę',
        'DELETE_SUCCESS': 'Usunięto firmę.',
        'DELETE_ERROR': 'Nie udało się usunąć firmy.',
        'ERROR': 'Wystąpił błąd. Spróbuj ponownie.',
        'NIP_LENGTH_ERROR': 'Numer NIP musi się składać z 10 cyfr.',
      }
    },
    'COMPANY_ACCOUNTS': {
      'TITLE_LIST': 'Konta',
      'TITLE_ADD': 'Dodaj konto',
      'TITLE_EDIT': 'Edytuj konto',
      'TITLE_VIEW': 'Szczegóły konta',
      'NAME': 'Imię',
      'SURNAME': 'Nazwisko',
      'EMAIL': 'Email',
      'PHONE': 'Numer telefonu',
      'STATUS': 'Status',
      'STATUSES': {
        'ACTIVE': 'Aktywny',
        'INACTIVE': 'Nieaktywny',
      },
      'CREATED_DATE': 'Data utworzenia',
      'UPDATED_DATE': 'Data edycji',

      'DELETE': 'Usuń',
      'ASSIGN': 'Przypisz',
      'CANCEL': 'Anuluj',
      'EDIT': 'Edytuj',
      'SAVE': 'Zapisz',

      'PASSWORD': {
        'CHANGE_PASSWORD': 'Zmień hasło',
        'PASSWORD': 'Hasło',
        'CONFIRM_PASSWORD': 'Powtórz hasło',
        'PASSWORDS_MIN_CHARACTERS': 'Hasło musi się składać z minimum 5 znaków.',
        'PASSWORDS_NOT_MATCH': 'Te hasła nie pasują do siebie.',
      },

      'MESSAGES': {
        'CREATE_SUCCESS': 'Dodano konto.',
        'CREATE_ERROR': 'Nie udało się dodać konta.',
        'CREATE_EMAIL_ERROR': 'Nie udało się dodać konta. Takie konto już istnieje.',
        'UPDATE_SUCCESS': 'Zaktualizowano konto.',
        'UPDATE_ERROR': 'Nie udało się zaktualizować konta.',
        'UPDATE_EMAIL_ERROR': 'Nie udało się zaktualizować konta. Takie konto już istnieje.',
        'DELETE_QUESTION': 'Czy na pewno chcesz usunąć to konto?',
        'DELETE_TITLE': 'Usuń konto',
        'DELETE_SUCCESS': 'Usunięto konto.',
        'DELETE_ERROR': 'Nie udało się usunąć konta.',
        'ASSIGN_QUESTION': 'Takie konto już istnieje. Czy chcesz je przypisać do firmy?',
        'ASSIGN_TITLE': 'Konto już istnieje',
        'ASSIGN_SUCCESS': 'Przypisano konto do firmy.',
        'ASSIGN_ERROR': 'Nie udało się przypisać konta do firmy.',
        'ERROR': 'Wystąpił błąd. Spróbuj ponownie.'
      }
    },
    'COMPANY_BRANCHES': {
      'TITLE_LIST': 'Oddziały',
      'TITLE_ADD': 'Dodaj oddział',
      'TITLE_EDIT': 'Edytuj oddział',
      'TITLE_VIEW': 'Szczegóły oddziału',
      'ADDRESS': 'Adres',
      'NAME': 'Nazwa',
      'CONTACT': 'Osoba kontaktowa',
      'EMAIL': 'Email',
      'PHONE': 'Numer telefonu',
      'STREET': 'Ulica i numer',
      'POSTAL_CODE': 'Kod pocztowy',
      'CITY': 'Miasto',
      'COUNTRY': 'Kraj',
      'NOTE': 'Uwagi',
      'CLIENT_COMMENT': 'Uwagi od klienta',
      'MAIN_BRANCH': 'Oddział główny',
      'CREATED_DATE': 'Data utworzenia',
      'UPDATED_DATE': 'Data edycji',
      'DELETE': 'Usuń',
      'CANCEL': 'Anuluj',
      'EDIT': 'Edytuj',
      'SAVE': 'Zapisz',


      'MESSAGES': {
        'CREATE_SUCCESS': 'Dodano oddział.',
        'CREATE_ERROR': 'Nie udało się dodać oddziału.',
        'UPDATE_SUCCESS': 'Zaktualizowano oddział.',
        'UPDATE_ERROR': 'Nie udało się zaktualizować oddziału.',
        'DELETE_QUESTION': 'Czy na pewno chcesz usunąć ten oddział?',
        'DELETE_TITLE': 'Usuń oddział',
        'DELETE_SUCCESS': 'Usunięto oddział.',
        'DELETE_ERROR': 'Nie udało się usunąć oddziału.',
        'ERROR': 'Wystąpił błąd. Spróbuj ponownie.',
        'PHONE_FORMAT': 'Błędny format numeru telefonu'
      }
    },
    'COMPANY_CARS': {
      'TITLE_LIST': 'Samochody',
      'TITLE_ADD': 'Dodaj samochód',
      'TITLE_EDIT': 'Edytuj samochód',
      'TITLE_VIEW': 'Szczegóły samochodu',
      'NAME': 'Nazwa',
      'CAR_TYPE': 'Typ samochodu',
      'CUSTOM_ID': 'Identyfikator wewnętrzny',
      'REGISTRATION_FRONT': 'Numer rej. przód',
      'REGISTRATION_BACK': 'Numer rej. tył',
      'DRIVER_NAME': 'Kierowca',
      'DRIVER_PHONE': 'Nr tel. kierowcy',
      'BDO': 'BDO',
      'COMPANY_NAME': 'Nazwa firmy',
      'COMMENTS': 'Komentarz',
      'COMPANY_BRANCH': 'Oddział',
      'REGISTRATION': 'Nr rejestracyjny',
      'CREATED_DATE': 'Data utworzenia',
      'UPDATED_DATE': 'Data edycji',
      'DELETE': 'Usuń',
      'CANCEL': 'Anuluj',
      'EDIT': 'Edytuj',
      'SAVE': 'Zapisz',
      'MESSAGES': {
        'CREATE_SUCCESS': 'Dodano samochód.',
        'CREATE_ERROR': 'Nie udało się dodać samochodu.',
        'UPDATE_SUCCESS': 'Zaktualizowano samochód.',
        'UPDATE_ERROR': 'Nie udało się zaktualizować samochodu.',
        'DELETE_QUESTION': 'Czy na pewno chcesz usunąć ten samochód?',
        'DELETE_TITLE': 'Usuń samochód',
        'DELETE_SUCCESS': 'Usunięto samochód.',
        'DELETE_ERROR': 'Nie udało się usunąć samochodu.',
        'ERROR': 'Wystąpił błąd. Spróbuj ponownie.',
        'PHONE_FORMAT': 'Błędny format numeru telefonu'
      }
    },
    'PACKAGE': {
      'TITLE_LIST': 'Sposoby pakowania',
      'TITLE_ADD': 'Dodaj sposób pakowania',
      'TITLE_EDIT': 'Edytuj sposób pakowania',
      'TITLE_VIEW': 'Szczegóły sposobu pakowania',
      'NAME': 'Nazwa',
      'DESC': 'Opis',
      'LENGTH': 'Długość',
      'WIDTH': 'Szerokość',
      'HEIGHT': 'Wysokość',
      'VOLUME': 'Objętość',
      'CAPACITY': 'Ładowność',
      'UNIT': 'Jednostka',
      'UNIT_TYPES': {
        'KG': 'Kilogram',
        'PIECES': 'Sztuka',
        'M3': 'm3'
      },
      'VISIBLE_BY_CLIENT': 'Widoczny dla klienta',
      'VISIBLE_STATUSES': {
        'VISIBLE': 'Widoczny',
        'INVISIBLE': 'Niewidoczny',
      },
      'STATUS': 'Status',
      'STATUSES': {
        'ACTIVE': 'Aktywny',
        'INACTIVE': 'Nieaktywny',
      },
      'SEARCH_PLACEHOLDER': 'Szukaj sposobu pakowania',
      'DELETE': 'Usuń',
      'CANCEL': 'Anuluj',
      'EDIT': 'Edytuj',
      'SAVE': 'Zapisz',

      'MESSAGES': {
        'CREATE_SUCCESS': 'Dodano sposób pakowania.',
        'CREATE_ERROR': 'Nie udało się dodać sposobu pakowania.',
        'UPDATE_SUCCESS': 'Zaktualizowano sposób pakowania.',
        'UPDATE_ERROR': 'Nie udało się zaktualizować sposobu pakowania.',
        'DELETE_QUESTION': 'Czy na pewno chcesz usunąć ten sposób pakowania?',
        'DELETE_PACKAGE_TITLE': 'Usuń sposób pakowania',
        'DELETE_SUCCESS': 'Usunięto sposób pakowania.',
        'DELETE_ERROR': 'Nie udało się usunąć sposobu pakowania.',
        'ERROR': 'Wystąpił błąd. Spróbuj ponownie.'
      }
    },
    'RECYCLING': {
      'TITLE_LIST': 'Sprzęt do utylizacji',
      'TITLE_ADD': 'Dodaj sprzęt do utylizacji',
      'TITLE_EDIT': 'Edytuj sprzęt do utylizacji',
      'TITLE_VIEW': 'Szczegóły sprzętu do utylizacji',
      'NAME': 'Nazwa',
      'CODE': 'Kod',
      'DESC': 'Rodzaj odpadu',
      'WEIGHT_PER_PIECE': 'Waga 1 sztuki',
      'WEIGHT_PER_CUBIC_METER': 'Waga na 1 m',
      'ITEM_WEIGHT': 'waga sprzętu:',
      'UNIT_TYPES': {
        'KG': 'Kilogram',
        'PIECES': 'Sztuka',
        'M3': 'm3',
        'KGS': 'kg'
      },
      'VISIBLE_BY_CLIENT': 'Widoczny dla klienta',
      'VISIBLE_STATUSES': {
        'VISIBLE': 'Widoczny',
        'INVISIBLE': 'Niewidoczny',
      },
      'STATUS': 'Status',
      'STATUSES': {
        'ACTIVE': 'Aktywny',
        'INACTIVE': 'Nieaktywny',
      },
      'SEARCH_PLACEHOLDER': 'Szukaj sprzętu do utylizacji',
      'DELETE': 'Usuń',
      'CANCEL': 'Anuluj',
      'EDIT': 'Edytuj',
      'SAVE': 'Zapisz',

      'MESSAGES': {
        'CREATE_SUCCESS': 'Dodano sprzęt do utylizacji.',
        'CREATE_ERROR': 'Nie udało się dodać sprzętu do utylizacji.',
        'UPDATE_SUCCESS': 'Zaktualizowano sprzęt do utylizacji.',
        'UPDATE_ERROR': 'Nie udało się zaktualizować sprzętu do utylizacji.',
        'DELETE_QUESTION': 'Czy na pewno chcesz usunąć ten sprzęt do utylizacji?',
        'DELETE_RECYCLING_TITLE': 'Usuń sprzęt do utylizacji',
        'DELETE_SUCCESS': 'Usunięto sprzęt do utylizacji.',
        'DELETE_ERROR': 'Nie udało się usunąć sprzętu do utylizacji.',
        'ERROR': 'Wystąpił błąd. Spróbuj ponownie.'
      }
    },
    'NO_ACCESS': {
      'MESSAGE': 'Nie masz dostępu do tej części systemu.'
    },
    'HISTORY': {
      'TITLE_ORDER': 'Historia zlecenia',
      'TITLE_TASK': 'Historia zadania',
      'USER': 'Użytkownik',
      'ANONYMOUS_CLIENT': 'anonimowy klient',
      'ADDED_CHANGES': 'Dodane wartości',
      'DELETED_VALUE': 'WARTOŚĆ USUNIĘTO',
      'CHANGES': 'Zmiany:',
      'EVENT_TYPES': {
        'CREATE': 'Utworzenie',
        'UPDATE': 'Edycja',
        'HOURPATCH': 'Zmiana daty',
        'CANCEL': 'Anulowanie',
        'DELETE': 'Usunięcie',
        'FILEUPLOAD': 'Dodanie pliku',
        'FILEDELETED': 'Usunięcie pliku',
      },
      'ORDER': {
        'ASC': 'Od najstarszego do najnowszego',
        'DESC': 'Od najnowszego do najstarszego'
      },
      'HISTORY_KEYS': {
        'taskData': 'Zlecenie',
        'order': 'Zlecenie',
        'status': 'Status',
        'orderData': 'Sprzęt do utylizacji',
        'fractions': 'Frakcje:',
        'fractionsAdded': 'Dodano frakcje:',
        'fractionsRemoved': 'Usunięto frakcje:',
        'tags': 'Tagi:',
        'tagsAdded': 'Dodano tagi:',
        'tagsRemoved': 'Usunięto tagi:',
        'totalWeight': 'Waga zamówienia:',
        'weight': 'Waga:',
        'value': 'Ilość:',
        'unit': 'Jednostka:',
        'kg': 'kg',
        'm3': 'm3',
        'pieces': 'sztuki',
        'unitpieces': 'sztuki',
        'title': 'Tytuł:',
        'name': 'Nazwa:',
        'companyName': 'Firma:',
        'companyBranch': 'Oddział:',
        'transport': 'Środek transportu:',
        'targetPlace': 'Miejsce docelowe:',
        'pickUpPlace': 'Miejsce odbioru:',
        'comment': 'Komentarz:',
        'salesAccepted': 'Zaakceptowane przez dział handlowy: ',
        'yes': 'Tak',
        'no': 'Nie',
        'recyclingItem': 'Sprzęt do utylizacji',
        'recyclingItems': 'Sprzęty do utylizacji',
        'packageTypes': 'Sposoby pakowania',
        'packageType': 'Sposób pakowania',
        'orderChanged': 'Zmiany w zleceniu',
        'registrationNumFront': 'Numer rejestracyjny przód',
        'registrationNumBack': 'Numer rejestracyjny tył',
        'taskReferenceNumber': 'Numer referencyjny ZT',
        'orderReferenceNumber': 'Numer referencyjny ZO',
        'taskDate': 'Data zadania:',
        'taskStartHour': 'Godzina rozpoczęcia zadania:',
        'taskEndHour': 'Godzina zakończenia zadania:',
        'removed': 'Usunięto zlecenia',
        'removedSingle': 'Usunięto zlecenie',
        'added': 'Dodano zlecenia',
        'addedSingle': 'Dodano zlecenie',
        'changed': 'Zmieniono zlecenia',
        'changedSingle': 'Zmieniono zlecenie',
        'noIdOrdersBefore': 'Inne zlecenia przed zmianą',
        'noIdOrdersAfter': 'Inne zlecenia po zmianie',
        'suggestedDate': 'Sugerowana data/Zaplanowana data: ',
        'availableHoursFrom': 'Możliwe godziny odbioru od: ',
        'availableHoursTo': 'Możliwe godziny odbioru do: ',
        'street': 'Ulica:',
        'postalCode': 'Kod pocztowy:',
        'city': 'Miasto:',
        'country': 'Kraj:',
        'recyclingItemAdded': 'Dodano sprzęt do utylizacji',
        'recyclingItemsAdded': 'Dodano sprzęty do utylizacji',
        'recyclingItemRemoved': 'Usunięto sprzęt do utylizacji',
        'recyclingItemsRemoved': 'Usunięto sprzęty do utylizacji',
        'recyclingItemChanged': 'Zmieniono sprzęt do utylizacji',
        'recyclingItemsChanged': 'Zmieniono sprzęty do utylizacji',
        'packageTypeAdded': 'Dodano sposób pakowania',
        'packageTypesAdded': 'Dodano sposoby pakowania',
        'packageTypeRemoved': 'Usunięto sposób pakowania',
        'packageTypesRemoved': 'Usunięto sposoby pakowania',
        'packageTypeChanged': 'Zmieniono sposób pakowania',
        'packageTypesChanged': 'Zmieniono sposoby pakowania',
        'noValue': '[ brak wartości ]'
      }
    },
    'REPORTS': {
      'TITLE': 'Raporty',
      'SUM': 'Suma',
      'KG': '[kg]',
      'WEEK': 'Tydzień',
      'MONTH': 'Miesiąc',
      'YEAR': 'Rok',
      'MONTHS': {
        '1': 'styczeń',
        '2': 'luty',
        '3': 'marzec',
        '4': 'kwiecień',
        '5': 'maj',
        '6': 'czerwiec',
        '7': 'lipiec',
        '8': 'sierpień',
        '9': 'wrzesień',
        '10': 'październik',
        '11': 'listopad',
        '12': 'grudzień',
      },
      'ALL': 'Wszystkie oddziały'
    },
    'SHARED': {
      'LOGIN': 'Zaloguj',
      'EMAIL': 'Email',
      'PASS': 'Hasło',
      'CONFIRM_PASS': 'Powtórz hasło',
      'LOGIN_TITLE': 'Zaloguj się',
      'LOGIN_ERROR': 'Podany login lub hasło jest nieprawidłowe.',
      'ACCESS_ERROR': 'Przykro  nam ale nie masz dostępu do tej części systemu.',
      'NOT_ACTIVE_ERROR': 'Twoje konto jest nieaktywne, aktywuj konto lub skontaktuj się z Administratorem.',
      'USER_ERROR': 'Użytkownik nie istnieje.',
      'EMAIL_ERROR': 'Email jest niepoprawny.',
      'NO_EMAIL_ERROR': 'Email jest wymagany.',
      'NO_PASS_ERROR': 'Hasło jest wymagane.',
      'FORGOT_PASS': 'Zapomniałeś hasła?',
      'RESET_PASS': 'Zresetuj hasło',
      'SET_PASS': 'Ustaw hasło',
      'RESET_PASS_TIP': 'Podaj adres e-mail - wyślemy Ci link resetujący hasło.',
      'NO_DATA': 'Brak danych',
      'NO_RESULTS': 'Brak wyników',
      'CANCEL': 'Anuluj',
      'DELETE': 'Usuń',
      'SEND': 'Wyślij',
      'CLEAR': 'Wyczyść',
      'OR': 'LUB',

      'MESSAGES': {
        'FORGOT_PASSWORD_SUCCESS': 'Na podanego maila został wysłany link resetujący hasło.',
        'SET_PASSWORD_SUCCESS': 'Hasło zostało zapisane.',
        'RESET_PASSWORD_SUCCESS': 'Hasło zostało zmienione.',
        'PASSWORDS_MIN_CHARACTERS': 'Hasło musi się składać z minimum 5 znaków.',
        'PASSWORDS_NOT_MATCH': 'Te hasła nie pasują do siebie.',
        'ERROR': 'Wystąpił błąd. Spróbuj ponownie.',
        'NO_DECIMAL_ERROR': 'Dozwolone tylko liczby całkowite większe od 0',
        'DECIMAL_ERROR': 'Dozwolony format: 0.00',
        'DECIMAL_ERROR_THREE': 'Dozwolony format: 0.000',
        'SMALLER_EQUAL_ZERO': 'Wartość musi być większa od 0',
        'NO_CREDENTIALS': 'Nie posiadasz uprawnień do tej części systemu',
        'SESSION_EXPIRED': 'Sesja wygasła, zaloguj się ponownie',
        'FILE_TOO_BIG': 'Plik za duży. Maksymalny rozmiar pliku to 5MB',
        'FILE_WRONG_FORMAT': 'Złe rozszerzenie pliku. Dozwolone formaty pliku to pdf, jpg, jpeg, png, gif, bmp.',
        'CLOSE_DIALOG_WITHOUT_SAVE': 'Czy napewno zamknąć okno bez zapisywania zmian?',
        'USAVED_DATA': 'Niezapisane dane',
      },
      'FILTERS': {
        'HEADER': 'Filtruj',
        'CLEAR': 'Wyczyść',
        'FILTER_TYPES': {
          'ROLETYPE': 'Rola',
          'USERTYPE': 'Użytkownik',
          'COMPANYBRANCHUUID': 'Oddział',
          'COMPANYBRANCHID': 'Oddział',
          'TASKTYPE': 'Typ zadania',
          'COMPANY': 'Firma',
          'OWNERID': 'Opiekun',
          'ACTIVE': 'Status',
          'TRUSTED': 'Zaufanie',
          'ISPUBLIC': 'Widoczność',
          'ORDERTYPE': 'Typ',
          'STATUS': 'Status',
          'SALESACCEPTED': 'Zaakceptowane',
          'DATEFROM': 'Od',
          'DATETO': 'Do',
          'TASKDATE': 'Data zadania'
        },
        'FILTERS_OPTIONS': {
          'ALL_USERS': 'Wszyscy',
          'ALL': 'Wszystkie',
          'SHOW_ALL': 'Pokaż wszystkie',
          'LOGISTICIAN': 'Logistyk',
          'MANAGER': 'Kierownik',
          'SALES': 'Handlowiec',
          'ADMIN': 'Administrator',
          'USER': 'Użytkownik',
          'TRUSTED': 'Zaufany',
          'NOT_TRUSTED': 'Niezaufany',
          'VISIBLE': 'Widoczny',
          'INVISIBLE': 'Niewidoczny',
          'ACTIVE': 'Aktywny',
          'INACTIVE': 'Nieaktywny',
          'YES': 'Tak',
          'NO': 'Nie',
          'RECEIVING': 'Odbiór transportem REMONDIS',
          'IMPORT': 'Przywóz - dostawa transportem klienta',
          'EXPORT': 'Wywóz',
          'NEW': 'Nowe',
          'READ': 'Odczytane',
          'PLANNED': 'Umówione',
          'CANCELLED': 'Anulowane',
          'CANCELLED_BY_CLIENT_REQUEST': 'Anulowane przez klienta',
          'COMPLETED': 'Zrealizowane'
        }
      },

      'PAGINATION': {
        'ITEMS_PER_PAGE': 'Elementów na stronie:',
        'NEXT_PAGE': 'Następna strona',
        'PREV_PAGE': 'Poprzednia strona',
        'RANGE_PAGE_LABEL_1': '0 z {{length}}',
        'RANGE_PAGE_LABEL_2': '{{startIndex}} - {{endIndex}} z {{length}}'
      },

      'WEEK': 'Tydzień',
      'WORKWEEK': 'Tydzień roboczy',
      'MONTH': 'Miesiąc',
      'DAY': 'Dzień',
      'TODAY': 'Dziś',
      'YES': 'Tak',
      'NO': 'Nie',
      'WEEKDAYS': {
        0: 'Poniedziałek',
        1: 'Wtorek',
        2: 'Środa',
        3: 'Czwartek',
        4: 'Piątek',
        5: 'Sobota',
        6: 'Niedziela'
      },
      'VALIDATORS': {
        'INCORRECT_EMAIL': 'Niepoprawny adres e-mail'
      }
    }
  }
};
