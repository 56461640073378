import { Injectable } from '@angular/core';
import { UserTypes } from '../shared/models/users.model';
import { FuseNavigationService } from '../../@fuse/components/navigation/navigation.service';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  constructor(private _fuseNavigationService: FuseNavigationService) {
  }

  navigation(userType?, isUpdate?) {
    const navigation = [
      {
        id: 'applications',
        title: '',
        type: 'group',
        children: [
          {
            id: 'tasks',
            title: 'Zadania',
            translate: 'NAV.MAIN.TASKS',
            type: 'item',
            icon: 'check_box',
            url: '/tasks'
          },

        ]
      }
    ];

    if (isUpdate) {
      this._fuseNavigationService.unregister('main');
      this._fuseNavigationService.register('main', navigation);
      this._fuseNavigationService.setCurrentNavigation('main');
    }

    return navigation;
  }
}
