var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnDestroy, OnInit } from '@angular/core';
import { FuseTranslationLoaderService } from '../../../../../@fuse/services/translation-loader.service';
import { locale as polish } from '../../../../translations/pl';
import { locale as english } from '../../../../translations/en';
import { MatDialog } from '@angular/material';
import { List } from '../../../../shared/models/list.class';
import { UserTypes } from '../../../../shared/models/users.model';
import { FormControl } from '@angular/forms';
import { AuthService } from '../../../../services/auth.service';
import { UsersService } from '../../../../services/users.service';
import { registerLocaleData } from '@angular/common';
import localePl from '@angular/common/locales/pl';
import { TasksService } from '../../../../services/tasks.service';
import { TaskTypes } from '../../../../shared/models/tasks.model';
// import { TaskTypePickerComponent } from '../task-type-picker/task-type-picker.component';
import { TaskExportFormComponent } from '../task-forms/task-export-form/task-export-form.component';
// import {TaskImportFormComponent} from '../task-forms/task-import-form/task-import-form.component';
import { TaskReceivingFormComponent } from '../task-forms/task-receiving-form/task-receiving-form.component';
import { TranslateService } from '@ngx-translate/core';
var TasksListComponent = /** @class */ (function (_super) {
    __extends(TasksListComponent, _super);
    function TasksListComponent(tasksService, matDialog, authService, usersService, translateService, _fuseTranslationLoaderService) {
        var _this = _super.call(this) || this;
        _this.tasksService = tasksService;
        _this.matDialog = matDialog;
        _this.authService = authService;
        _this.usersService = usersService;
        _this.translateService = translateService;
        _this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        _this.displayedColumns = ['actions', 'taskNumber', 'branch', 'createdDate', 'startDate', 'taskName', 'forwarderExpense',
            'forwarderInvoiceNumber', 'forwarderCompanyName', 'status', 'forwarderRegistrationNumber', 'forwarderDriver',
            'forwarderCarType', 'comment', 'cargo', 'suggestedDate', 'taskType'];
        _this.userTypes = UserTypes;
        _this.companyBranches = [];
        _this.tableFilters = ['taskType'];
        _this.objectKeys = Object.keys;
        _this.branchIndex = new FormControl(0);
        _this.taskTypes = TaskTypes;
        _this.listFilters = {};
        _this.initialUserFilters = { companyBranchId: [] };
        registerLocaleData(localePl);
        _this.user = _this.authService.user.user;
        _this._fuseTranslationLoaderService.loadTranslations(polish, english);
        _this.statusFilters = [
            { name: _this.translateService.instant('SHARED.FILTERS.FILTERS_OPTIONS.NEW'), value: 'new' },
            { name: _this.translateService.instant('SHARED.FILTERS.FILTERS_OPTIONS.CANCELLED'), value: 'cancelled' }
        ];
        _this.requestParams.sort = 'createdDate';
        _this.requestParams.order = 'desc';
        _this.reloadList$ = tasksService.onEditEvent.subscribe(function () {
            _this.getElementsList();
        });
        _this.getElementsList = function () {
            _this.tasksService.getTasksList(_this.getQueryParams())
                .subscribe(function (res) {
                res.tasks.map(function (task) {
                    task.companies = [];
                    task.customerCompanyBranches = [];
                    task.packages = [];
                    if (task.taskType === TaskTypes.Import || task.taskType === TaskTypes.Receiving) {
                        task.packages = [];
                        task.taskData.forEach(function (order) {
                            order.orderData.forEach(function (data) {
                                data.packageTypes.forEach(function (packageType) {
                                    if (task.packages.filter(function (type) { return type.name === packageType.name; }).length === 0) {
                                        task.packages.push(packageType);
                                    }
                                    else {
                                        task.packages.forEach(function (item) {
                                            if (item.name === packageType.name) {
                                                item.value += packageType.value;
                                            }
                                        });
                                    }
                                });
                            });
                        });
                    }
                    task.taskData.forEach(function (order) {
                        task.customerCompanyBranches.push(order.customerCompanyBranch);
                        if (task.companies.filter(function (item) { return item.uuid === order.customerCompany.uuid; }).length === 0) {
                            task.companies.push(order.customerCompany);
                        }
                    });
                });
                _this.totalCount = res.totalCount;
                _this.dataSource = res.tasks;
            });
        };
        _this.requestParams.sort = 'createdDate';
        return _this;
    }
    TasksListComponent.prototype.ngOnInit = function () {
        var _this = this;
        _super.prototype.ngOnInit.call(this);
        this.createSearchFormControl();
        this.getElementsList();
        this.usersService.getCompanyBranchList().subscribe(function (res) {
            res.branches.forEach(function (branch) {
                _this.companyBranches.push({ name: branch.name, value: branch.uuid });
                _this.initialUserFilters.companyBranchId.push(branch.uuid);
            });
        });
    };
    TasksListComponent.prototype.ngOnDestroy = function () {
        this.reloadList$.unsubscribe();
    };
    TasksListComponent.prototype.clearSearch = function () {
        if (this.searchTask.value) {
            this.searchTask.setValue('');
        }
    };
    TasksListComponent.prototype.getListFilters = function (filters) {
        this.listFilters = Object.assign({}, filters);
        this.requestParams.page = 1;
        this.disableAnimations = true;
        this.getElementsList();
    };
    TasksListComponent.prototype.createSearchFormControl = function () {
        var _this = this;
        this.searchTask = new FormControl('');
        this.searchTask.valueChanges.debounceTime(300).subscribe(function (value) {
            _this.requestParams.search = value;
            _this.requestParams.page = 1;
            _this.disableAnimations = true;
            _this.getElementsList();
        });
    };
    TasksListComponent.prototype.getQueryParams = function () {
        return Object.assign({}, this.requestParams, this.listFilters);
    };
    TasksListComponent.prototype.editTask = function (taskId, taskType) {
        if (taskType === 'export') {
            this.matDialog.open(TaskExportFormComponent, {
                panelClass: 'task-editor-dialog',
                autoFocus: false,
                disableClose: true,
                data: {
                    mode: 'view',
                    taskId: taskId
                }
            });
            // } else if (taskType === 'import') {
            // this.matDialog.open(TaskImportFormComponent, {
            //   panelClass: 'task-editor-dialog',
            //   autoFocus: false,
            //   disableClose: true,
            //   data: {
            //     mode: 'view',
            //     taskId: taskId
            //   }
            // });
        }
        else if (taskType === 'receiving') {
            this.matDialog.open(TaskReceivingFormComponent, {
                panelClass: 'task-editor-dialog',
                autoFocus: false,
                disableClose: true,
                data: {
                    mode: 'view',
                    taskId: taskId
                }
            });
        }
    };
    return TasksListComponent;
}(List));
export { TasksListComponent };
