import { EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { AuthService } from './auth.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./auth.service";
var CompanyCarsService = /** @class */ (function () {
    function CompanyCarsService(http, authService) {
        this.http = http;
        this.authService = authService;
        this.onEditEvent = new EventEmitter();
    }
    CompanyCarsService.prototype.getCompanyCarsList = function (params, companyUuid) {
        return this.http.get(environment.apiUrl + "/" + this.authService.getUserCompanyId() + "/cars", { params: params }).pipe(map(function (res) { return res; }));
    };
    CompanyCarsService.prototype.getCompanyCar = function (companyUuid, companyCarUuid) {
        return this.http.get(environment.apiUrl + "/" + this.authService.getUserCompanyId() + "/cars/" + companyCarUuid)
            .pipe(map(function (res) { return res.car; }));
    };
    CompanyCarsService.prototype.createCompanyCar = function (companyUuid, car) {
        return this.http.post(environment.apiUrl + "/" + this.authService.getUserCompanyId() + "/cars", car)
            .pipe(map(function (res) { return res.car; }));
    };
    CompanyCarsService.prototype.updateCompanyCar = function (companyUuid, carUuid, car) {
        return this.http.post(environment.apiUrl + "/" + this.authService.getUserCompanyId() + "/cars/" + carUuid, car);
    };
    CompanyCarsService.prototype.deleteCompanyCar = function (companyUuid, carUuid) {
        return this.http.delete(environment.apiUrl + "/" + this.authService.getUserCompanyId() + "/cars/" + carUuid);
    };
    CompanyCarsService.ngInjectableDef = i0.defineInjectable({ factory: function CompanyCarsService_Factory() { return new CompanyCarsService(i0.inject(i1.HttpClient), i0.inject(i2.AuthService)); }, token: CompanyCarsService, providedIn: "root" });
    return CompanyCarsService;
}());
export { CompanyCarsService };
